<template>
  <div class="px-2">
    <div class="row mx-0 my-2 filter">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Farmers ({{credits.length}})</h1>
        </div>
      </div>
      <div class="col-auto">
        <loan-filter @updateFilter="updatefilter" :filters="filters"/>
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0">
        <div class="card-header p-2">
          <div class="row">
            <season-filter @updateFilter="updatefilter" :filters="seasonfilter"/>
            <partner-filter @updateFilter="updatefilter" :filters="coopfilter" />
            <div class="col-auto pr-0">
              <input class="search form-control py-0" placeholder="search"  v-model="searchQuery" @keyup="onSearch" />
            </div>
            <div class="col-auto">
              <export-options page="AllFarmerCredit" :parameters="parameters" :url="pageUrl" :loading="loading"/>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-holder" ref="holder" :style="holderstyles">
            <table class="w-100 table-stripes table h table-secondary" ref="table">
              <thead>
                <th></th>

                <th>Name</th>

                <th>Phone</th>
                <th class="text-right">Credits Used</th>
                <th class="text-right">Credit Balance</th>

                <th width="1px"></th>
              </thead>
              <tbody>
                <tr v-for="(item,index) in credits" :key="index">
                  <td width="1px" class>{{index + 1}}</td>

                  <td class="txt-primary">{{(item.farmer_name.length>13?item.farmer_name.substring(0, 12)+'...':item.farmer_name) | capitalize}}</td>
                  <td class="text-muted">{{item.contact | contact}}</td>
                  <td class="text-right">
                    <b>{{item.used | addCommas}}</b>
                  </td>

                  <td class="text-right">
                    <b class="txt-primary">{{item.balance | addCommas}}</b>
                  </td>

                  <td class="text-muted">
                    <button
                      class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                      @click="showDetails(index)"
                    >View details</button>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" style="background:white;">
                <infinite-loading :identifier="infiniteId" @infinite="getcredits"></infinite-loading>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-auto">
              <span class="txt-primary">Total Farmers:</span>
              <b>{{credits.length}}</b>
            </div>

            <div class="col-auto">
              <span class="txt-primary">Used Credits:</span>
              <b>{{totalCreditsUsed}}</b>
            </div>

            <div class="col-auto">
              <span class="txt-primary">Unused credits:</span>
              <b>{{totalCreditsBalance}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader :isLoading="loading" />
  </div>
</template>

<script>
import Tables from "../mixins/Tables";
import { bus } from "../main.js";

import LoanFilter from "../components/LoanFilter";
import SeasonFilter from "../components/SeasonFilter";
import PartnerFilter  from "../components/PartnerFilter";
import ExportOptions from "../components/ExportOptions";
import Loader from "../components/Loader";

import axios from "axios";
export default {
  name: "CreditsFarmers",
  components: { SeasonFilter, LoanFilter, PartnerFilter, ExportOptions, Loader },
  props: {
    maID: String,
  },
  mixins: [Tables],
  data() {
    return {
      credits: [],

      loading: false,
      totalLoanAmount: 0,
      totalLoanBalance: 0,
      totalCreditsUsed:0,
      totalCreditsBalance:0,
      user:null,
      url: process.env['VUE_APP_BACKEND_URL'],
      itemSelected: [],
      filters:{
      

        district: "All districts",
        partner: "",
        dates: {
        start: new Date(),
        end:  new Date(),
        
      }},
      coopfilter:{
        farmerType:'',
        cooperative:''
      },
      seasonfilter:{
        season:'',
        page:'All Farmer Credits'
      },
      page: 1,
      searchQuery:'',
      infiniteId: +new Date(),
      holderstyles: {
        height: "0px",
      },
      pageUrl: process.env['VUE_APP_BACKEND_URL']+"api/v2/credit",
      parameters:{},
      loanSelected: [],
    };
  },
  created() {
    this.checkUser(this);

    this.$nextTick(function () {
      this.setTableHeight("holder", this.holderstyles);
    });
  },

  methods: {
    checkUser(dataObj){
      sessionStorage.getItem('token')?(dataObj.user = sessionStorage.getItem('token')): dataObj.$router.push('/');
    },
    showDetails(index) {

      this.itemSelected = this.credits[index];
      bus.$emit("show-credit-details", this.itemSelected);
    },

    getcredits($state) {
      let dataObj = this;
      dataObj.parameters = {
        page: dataObj.page,
        name: dataObj.searchQuery,
        district: dataObj.filters.district==='All districts'?'':dataObj.filters.district,
        start: dataObj.formatDate(dataObj.filters.dates.start),
        end:dataObj.formatDate(dataObj.filters.dates.end),
        maID:dataObj.coopfilter.cooperative,
        farmerType:dataObj.coopfilter.farmerType,
        season:dataObj.seasonfilter.season,
        partner: dataObj.filters.partner==='All Partners'?'':dataObj.filters.partner,
      }
      axios
        .get(dataObj.pageUrl, {
          headers:{'Authorization': 'Bearer ' + dataObj.user},
          params: dataObj.parameters,
        })
        .then(function (response) {

          dataObj.page++;
          if(response.data.credits.length>=25){
            dataObj.credits = dataObj.credits.concat(response.data.credits);
            $state.loaded();
          }
          else if(response.data.credits.length>0) {
            dataObj.credits = dataObj.credits.concat(response.data.credits);

            $state.loaded();
            throw false;
          }
          else{
            throw false;
          }
          

        })
        .catch(function (error) {
          console.log(error);
          $state.complete();

        })

    },
    onSearch(){
      this.page = 1;
      this.parameters = {
        page: this.page,
        name: this.searchQuery,
        district: this.filters.district==='All districts'?'':this.filters.district,
        start: this.formatDate(this.filters.dates.start),
        end:this.formatDate(this.filters.dates.end),
        maID:this.coopfilter.cooperative,
        farmerType:this.coopfilter.farmerType,
        season:this.seasonfilter.season,
        partner: this.filters.partner==='All Partners'?'':this.filters.partner,
      }
      this.credits = [];
      this.infiniteId += 1;

    },
  },

  watch:{


    filters:{
      handler(){
        this.onSearch();
      },
      deep:true
    },
    coopfilter:{
      handler(){
        this.onSearch();
      },
      deep:true
    },
    seasonfilter:{
      handler(){
        this.onSearch();
      },
      deep:true
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dot {
  height: 10pt;
  width: 10pt;
  border-radius: 10pt;
  background-color: #e8e8e8;
  display: inline-block;
}
</style>
