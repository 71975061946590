<template>
    <div>
      <b-modal
        ref="loan-details-modal"
        body-class="mymodal-body"
        static-backdrop
        no-fade
        no-close-on-esc
        no-close-on-backdrop
        id="loan-details-modal"
        size="xl"
        scrollable
        hide-header
        hide-footer
        title
      >
        <div class="row m-0">
          <div class="col-3 left p-0">
            <div class="py-3 px-4">
              <h4>{{profile.name}}</h4>
              <p>
                Loan ID: #{{loan.loan_id}}
                <chevron-down-icon />
              </p>
            </div>
            <div class="tabs" role="tablist">
              <a
                class="tab-link"
                :class="{active: tab=='details'}"
                @click="switchtab('details')"
              >Loan details</a>
              <a
                class="tab-link"
                :class="{active: tab=='history'}"
                @click="switchtab('history')"
              >Loan History</a>
              <a
                class="tab-link"
                :class="{active: tab=='application'}"
                @click="switchtab('application')"
              >Loan application</a>
            </div>
          </div>
          <div class="col p-0 position-relative">
            <div
              class="py-2 bg-white border-bottom"
              style="box-shadow:3px 3px 5pt rgba(0,0,0,.06); position:relative; z-index:50"
            >
              <div class="row m-0">
                <div class="col-12 col-md">
                  <h4 class="m-0 text-capitalize m-title">{{tab}}</h4>
                </div>
                <div class="col-12 col-md-auto">
                  <button class="mr-2 light btn-menu">
                    <printer-icon />Print
                  </button>
                  <button @click="closeDetails" class="light btn-menu">
                    <x-icon></x-icon>Close
                  </button>
                </div>
              </div>
            </div>
            <div class="loans-content position-relative bg-light">
              <div
                class="tab-view"
                v-show="tab=='details'"
                key="details"
              >
                <div class="p-3 border">
                  <div class="row m-0">
                    <div class="col-lg-6 col-12">
                      <div class="row">
                        <div class="col-lg-6 col-12 pl-0">
                          <div class="label">Principal</div>
                          <div class="val text-primary-dark">{{loan.amount | addCommas}}</div>
  
                          <div class="label">Total loan</div>
                          <div v-show="loan.total && !loading" class="val">
                            {{loan.total | addCommas}}
                            <span
                              class="badge bage-success"
                            >{{loan.interest}}%</span>
                          </div>
  
                          <div class="label">Balance</div>
                          <div v-show="loan.balance && !loading" class="val">{{loan.balance  | addCommas}}</div>
                          <div v-show="loading" class="val">loading...</div>
                        </div>
  
                        <div class="col-lg-6 col-12">
                          <div
                            class="label"
                          >
                            <check-circle-icon />Date approved
                          </div>
                          <div
                            class="val"
                          >{{loan.time.split(' ')[0] | moment}}</div>
  
                          <div class="label">
                            <calendar-icon />Due date
                          </div>
                          <div class="val">{{ loan.due_date.split(' ')[0] | moment }}</div>
  
                          <div class="label">
                            <clock-icon />Payment period
                          </div>
                          <div class="val">
                            {{loan.due_date.split(' ')[0] | months }}
                            <small>month(s) from now</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-0 border-left">
                      <table class="tb table table-light">
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th class="text-right">Amount (UGX)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in loan.items" :key="index">
                            <td>{{item}}</td>
                            <td>
                              {{loan.quantities[index]}}
                              <small></small>
                            </td>
                            <td class="text-right">{{loan.quantities[index]*loan.unit_cost[index]| addCommas}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div v-if="loan.balance" class="row m-0">
                  <div class="col">
                    <h4 class="stitle mb-0 pt-2">Loan payments ({{payments?payments.length:0}})</h4>
                  </div>
                  <div class="col-auto pb-2 pt-1">
                    <button v-show="!loading" class="btn-menu" @click="newPayment()">
                      <plus-icon />Add payment
                    </button>
                    <button v-show="!loading" class="btn-menu" @click="reload">
                      <b-icon-arrow-clockwise/> Reload
                    </button>
                  </div>
                </div>
  
                <div
                  v-if="payments"
                  class="px-3"
                >
                  <table class="tb table table-secondary">
                    <thead>
                      <tr>
                        <th>payment date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <!-- <th class="text-center">Quantity</th> -->
                        <!-- <th class="text-right">unit price</th> -->
                        <!-- <th class="text-right">Amount</th> -->
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in payments" :key="index">
                        <td>{{item.date | moment}}</td>
                        <td>{{item.time }}</td>
                        <td>{{item.amount | addCommas}}</td>
                        <!-- <td>{{item.balance}}</td> -->
                        <td>
                          <a @click="editPayment(index)" class="btn">Edit</a>
                        </td>
                      </tr>
                      <tr v-if="!(payments.length>0) && !loading">
                        <td colspan="2">No payments...</td>
                        <!-- <td></td> -->
                        <td></td>
                        <!-- <td>{{item.balance}}</td> -->
                        <td>
                          <!-- <a @click="editPayment(index)" class="btn">Edit</a> -->
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="2">Loading...</td>
                        <!-- <td></td> -->
                        <td></td>
                        <!-- <td>{{item.balance}}</td> -->
                        <td>
                          <!-- <a @click="editPayment(index)" class="btn">Edit</a> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
  
              <div class="tab-view" v-show="tab=='history'" key="history">
                <div class="container">
                  <div class="p-3 row">
                  <h4 class="stitle col-sm-12">Loan History</h4>
                  <table v-if="!loadingHistory" class="table table-secondary col-sm-12">
                    <thead>
  
                      <tr>
                        <th class="br">Date</th>
                        <th class="br text-right">Principal</th>
                        <th class="br text-right">Total</th>
                        <th class="br text-right">Paid</th>
                        <th class="br text-right">Balance</th>
                        
  
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in history" :key="index">
                        <td class="text-left">{{item.date | moment}}</td>
                            <td  class="text-right">{{item.amount | addCommas}}</td>
                            <td class="text-right"> {{item.total | addCommas}}</td>
                            <td class="text-right">{{item.paid | addCommas}}</td>
                            <td class="text-right">{{item.balance | addCommas}}</td>
                            
                            
                          </tr>
                    </tbody>
                  </table>
                  <div class="py-2" v-if="loadingHistory">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                  <div v-if="false">
                  <h4 class="stitle mt-5 col-sm-12">Monitoring</h4>
                  <div class="row m-0">
                    <div
                      class="col-lg-3 col-12 pl-0"
                      v-for="(item, index) in monitoringdata"
                      :key="index"
                    >
                      <div class="card-usage">
                        <p class="m-0">{{item.key}}</p>
                        <h5 class="text-secondary font-weight-normal">{{item.value}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
                
              </div>
  
              <div class="tab-view bg-light p-0" v-show="tab=='application'" key="application">
                <div class="row m-0">
                  <div class="col py-3">
                    <div class="card app-card mb-3">
                      <h4 class="stitle">Bio info</h4>
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <p class="label">Name</p>
                          <p class="val">{{profile.name}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Age, Gender</p>
                          <p class="val">{{profile.farmer_dob | age}}, {{profile.gender}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Phone</p>
                          <p class="val">{{profile.contact | contact}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Email</p>
                          <p class="val">{{profile.email}}</p>
                        </div>
  
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Address</p>
                          <p class="val">{{profile.district}}, {{profile.village}}</p>
                          <p class="val">{{profile.address}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">ID card</p>
                          <p class="val">
                            {{profile.nin}}
                            <a @click="viewIdphoto=true" class="link-btn">View ID</a>
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="card app-card mb-3">
                      <h4 class="stitle">Additional info</h4>
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <p class="label">Education level</p>
                          <p class="val">{{profile.education}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Dependants</p>
                          <p class="val">{{profile.number_of_dependants}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Main source of income</p>
                          <p class="val">{{profile.other_income_source}}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Loan Eligibility</p>
                          <p class="val">
                            Yes
                            <check-circle-icon class="text-primary" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pl-0">
                    <div class="loan-summary px-2 py-3">
  
                      <p v-if="admin" class="label">Loan limit</p>
                      <p v-if="admin" class="val">{{ score_details.loan_limit?score_details.loan_limit:0 | addCommas}}</p>
  
                      <p class="label">Loan amount</p>
                      <p class="val text-primary">UGX {{loan.amount | addCommas}}</p>
  
                      <p class="label">Insurance</p>
                      <p class="val">{{loan.insurance}}</p>
  
                      <p v-if="admin" class="label">Eligibility</p>
                      <p v-if="admin" class="val">
                        <check-circle-icon class="text-primary" />Yes
                      </p>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <transition name="fade">
              <div class="submodal" v-show="editingpayment == true && tab=='details'" key="8290298">
                <form @submit.prevent>
                <div classs="p-3">
                  <h4 class="title mb-3">Add payment</h4>
                  
                  <label class="control-label">Method<span class="text-danger">*</span> </label>
                  <select  required class="form-control" v-model="paymentmodal.method">
                    <option 
                    v-for="(item,index) in paymentMethods" 
                    :key="item.key+index"
                    :value="item.value"
                    >{{item.key}}</option>
                  </select>
                  <b-form-group
                  v-show="paymentmodal.method==='cash'"
                  >
                  <label class="control-label">Reference ID<span class="text-danger">*</span></label>
                  <input required class="form-control" type="number" v-model="paymentmodal.referenceId"/>

                  <label class="control-label">Payment date<span class="text-danger">*</span></label>
                  <v-date-picker
                  required
                  
                    mode="single"
                    format="DD-MM-YYY"
                    v-model="paymentmodal.date"
                    :input-props="{
                    class: 'form-control',
                    }"
                />
                  </b-form-group>
                  
  

                  <label class="control-label">Total amount <span class="text-danger">*</span></label>
                  <input required class="form-control" type="number" v-model="paymentmodal.totalAmount" />
                  
                  <b-form-group
                  description="Enter user's phone number: 2567xxxxxxxx"
                  v-if="paymentmodal.method==='mobile'"
                  >
                  <label class="control-label">Phone
                  <span class="text-danger">*</span></label>
                    <b-form-input
                    type="number"
                    required
                    v-model="paymentmodal.phone"
                    >
                    </b-form-input>
                  </b-form-group>

                  <div class="py-2" v-if="isError">
                    <p class="text-danger">{{ errorMessage }}</p>
                  </div>
  
                  <div class="py-2" v-if="!loadingPayment">
                    <button class="mr-2" @click="cancelPayment()">Cancel</button>
                    <b-button 
                    v-if="paymentmodal.method==='cash'" 
                    type="submit"
                    variant="primary"
                    class="float-right"
                    @click="savePayment"
                     >Save payment</b-button>
                    <b-button 
                    v-if="paymentmodal.method==='mobile'" 
                    @click="initiateMomo"
                    type="submit"
                    variant="success"
                    class="float-right"
                    >Initiate payment</b-button>
                  </div>
                  <div class="py-2" v-if="loadingPayment">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                
                </div>
            </form>
              </div>
            </transition>
  
            <div class="submodal" v-show="editingapprove == true && tab=='application'" key="8290298">
              <div>
                <!-- approve loan section  -->
                <div id="approve-loan" v-if="approvesection=='approve'">
                  <h4 class="title mb-3">Approve loan</h4>
                  <!-- <div v-for="(item, index) in loan.items" :key="index">
                    <label class="control-label">{{item}}</label>
                    <input class="form-control" v-model="loan.cost[index]" />
                  </div>-->
                  <div>
                    <label class="control-label">Amount Applied for</label>
                    <input type="number" class="form-control" v-model="loan.amount" />
                  </div>
                  <hr class="mb-2" />
                  <label class="control-label">Interest rate</label>
                  <select class="form-control" v-model="interestRate">
                    <option
                      v-for="(rate, index) in interestRates"
                      :key="index"
                      :value="rate.value"
                    >{{rate.name}} - {{rate.value}}%</option>
                  </select>
                  <br />
                  <h4 class="stitle mb-1">Total Loan</h4>
                  <h4 class="font-weight-bold">UGX {{loan.amount | addCommas}}</h4>
                  <div class="py-2">
                    <button class="mr-2" @click="editingapprove=false;">Cancel</button>
                    <button @click="approveloan($data);" class="btn-success">Approve Loan</button>
                  </div>
                </div>
  
                <!-- reject loan section -->
                <div class="loader" v-if="loading">
                  <b-spinner label="Loading..."  variant="primary"></b-spinner>
                  <p>Loading..</p>
                </div>
  
                <div id="reject-loan" v-if="approvesection=='reject'">
                  <h4 class="title mb-3">Reject loan</h4>
  
                  <label class="control-label">Reason</label>
                  <textarea class="form-control" v-model="reason" rows="4"></textarea>
  
                  <div class="py-2">
                    <button class="mr-2" @click="editingapprove=false">Cancel</button>
                    <button class="danger" @click="rejectloan($data);">Reject Loan</button>
                  </div>
                </div>
  
                <div id="success-modal" v-if="approvesection=='success'">
                  <h4 class="title mb-3">Successfully {{response}}</h4>
  
                  <!-- <label class="control-label">Successful</label> -->
  
                  <div class="py-2">
                    <button class="btn-success" @click="editingapprove=false">Ok</button>
                  </div>
                </div>
  
                <div id="fail-modal" v-if="approvesection=='failed'">
                  <h4 class="title mb-3">{{response}}</h4>
  
                  <!-- <label class="control-label">Successful</label> -->
  
                  <div class="py-2">
                    <button class="btn-success" @click="editingapprove=false">Ok</button>
                  </div>
                </div>
              </div>
            </div>
  
            <div
              class="submodal text-center"
              v-show="viewIdphoto == true && tab=='application'"
              key="829029899"
              style="width:300pt"
            >
              <div class="text-center d-block">
                <img
                  class="img-fluid"
                  :src="profile.nin_photo_url?profile.nin_photo_url:''"
                />
              </div>
              <button class="m-auto" @click="viewIdphoto =false">Close</button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
    ChevronDownIcon,
    XIcon,
    PrinterIcon,
    CheckCircleIcon,
    ClockIcon,
    CalendarIcon,
    PlusIcon,
  } from "vue-feather-icons";
  import Tables from "../../mixins/Tables";
  import { bus } from "../../main";
  
  import axios from "axios";
  
  export default {
    name: "LoanDetails",
    components: {
      ChevronDownIcon,
      XIcon,
      PrinterIcon,
      CheckCircleIcon,
      ClockIcon,
      CalendarIcon,
      PlusIcon,
    },
    mixins: [Tables],
  
    data() {
      return {
        loan: {
            time:'',
            due_date:''
        },
        modalTitle: "Loan details",
        tab: "details",
        fetching: "loading...",
        loadingPayment:false,
        uri: process.env["VUE_APP_BACKEND_URL"],
        user: null,
        loading: false,
        loadingHistory:false,
        success: false,
        score_details: {},
        failed: false,
        credit_score: null,
        interestRate: null,
        editingpayment: false,
        editingapprove: false,
        approvesection: "",
        viewIdphoto: false,
        viewgardenmap: false,
        reason: null,
        response: null,
        history:[],
        profile:{},
        paymentMethods:[
        { key: 'Select', value:null },
        { key: 'Mobile', value:'mobile' },
        { key: 'Cash', value:'cash' },
        ],
        interestRates: [
          { name: "Standard", value: "3.0" },
          { name: "Old farmers", value: "2.5" },
        ], //get interest rates
        paymentmodal: {
          /*payment model */
          id: 0,
          date: new Date(),
          method: "cash",
          produce: "",
          quantity: 0,
          units: "",
          unitPrice: 0,
          totalAmount: 0,
          phone:'',
          referenceId:''
        },
        dataloading:{
          'loading...':0
        },
        coopData:{},
        admin: false,
  
  
        payments: [],
        old:[{date:'loading..', time:'', amount:0}],
        monitoringdata: [],
        usagedata: [],
        isError: false,
        errorMessage: "",
      };
    },
  
    methods: {
      closeDetails() {
        this.$bvModal.hide("loan-details-modal");
        bus.$emit("hidedetails");
      },
      reload(){
        this.getUserPayments();
        this.getUserProfile();
        this.getUserHistory();
      },
  
      switchtab(val) {
        this.tab = val;
      },
  
      newPayment() {
        this.editingpayment = true;
        this.resetPaymentModal();
        this.paymentmodal.phone=this.profile.contact;
      },
  
      editPayment(index) {
        this.editingpayment = true;
        this.resetPaymentModal();
        let pay = this.payments[index];
  
        /*set the payment modal to the clicked payment object*/
        this.paymentmodal.id = pay.id;
        this.paymentmodal.date = pay.date;
        this.paymentmodal.method = pay.method;
        this.paymentmodal.produce = pay.method;
        this.paymentmodal.quantity = pay.quantity;
        this.paymentmodal.unitPrice = pay.unitprice;
        this.paymentmodal.totalAmount = pay.amount;
        this.paymentmodal.units = "";
      },
  
      cancelPayment() {
        this.editingpayment = false;
        this.resetPaymentModal();
      },
  

      resetPaymentModal() {
        this.paymentmodal.id = "0";
        this.paymentmodal.date = "";
        this.paymentmodal.method = "cash";
        this.paymentmodal.produce = "";
        this.paymentmodal.quantity = 0;
        this.paymentmodal.unitPrice = 0;
        this.paymentmodal.totalAmount = 0;
        this.paymentmodal.units = "";
        this.paymentmodal.referenceId = "";
      },

      getUserPayments() {
        this.loading = true;
        this.payments = [];
  
        axios
          .get(this.uri + "api/v3/loans/pezesha/user/payments/", {
            headers: { Authorization: "Bearer " + this.user },
            params: {
              user_id: this.loan["user_id"],
              loan_id: this.loan["loan_id"]
            },
          })
          .then((response)=> {
            
            this.payments = response.data;
            this.loading = false;
          })
          .catch(()=> {
            this.$bvToast.toast(`Failed to load payments`,{
                variant:'danger',
                title:'Failed to load payments'
            })
            this.loading = false;
          });
      },
      getUserHistory() {
        this.loadingHistory = true;
  
        axios
          .get(this.uri + "api/v3/loans/pezesha/user/history", {
            headers: { Authorization: "Bearer " + this.user },
            params: {
              user_id: this.loan["pezesha_id"],

            },
          })
          .then( (response)=> {
            this.history = response.data;
            this.loadingHistory = false;
          })
          .catch((error)=> {
            this.$bvToast.toast(error.response.data.message,{
                variant:'danger',
                title:'Failed to load payments'
            })
            this.loadingHistory = false;
          });
      },
       getUserProfile() {
        this.loading = true;
  
        axios
          .get(this.uri + "api/v3/loans/pezesha/user/", {
            headers: { Authorization: "Bearer " + this.user },
            params: {
              user_id: this.loan["user_id"],
            },
          })
          .then( (response)=> {
            this.profile = response.data[0];
            this.loading = false;
          })
          .catch(()=> {
            this.loading = false;
          });
      },
      savePayment() {
        if(this.paymentmodal.referenceId == ''){
          this.isError = true;
          this.errorMessage = "Please enter a reference ID";
        }
        else if(this.paymentmodal.totalAmount == 0){
          this.isError = true;
          this.errorMessage = "Please enter an amount";
        }
        else if(this.paymentmodal.date == ""){
          this.isError = true;
          this.errorMessage = "Please select a date";
        }
        else{
          this.isError = false;
          this.errorMessage = "";
          this.loadingPayment = true;
          
          axios
          .post(this.uri + "api/v3/loans/pezesha/user/cash-repayment", {
            phoneNumber: this.paymentmodal.phone,
            Amount: this.paymentmodal.totalAmount,
            loan_id: this.loan.loan_id,
            user_id: this.loan.user_id,
            farmer_name: this.profile.name,
            pezesha_id:this.loan.pezesha_id,
            date:this.paymentmodal.date,
            referenceId: this.paymentmodal.referenceId
          },{
            headers: { Authorization: "Bearer " + this.user },
          })
          .then( ()=> {
            this.editingpayment = false;
            this.resetPaymentModal();
            this.$bvToast.toast('Successfully saved.',{
                variant:'success',
                title:'successful'
            })
            this.loadingPayment = false;
          })
          .catch((error)=> {
            this.editingpayment = false;
            this.resetPaymentModal();
            this.$bvToast.toast(error.response.data.message,{
                variant:'danger',
                title:'Failed'
            })
            this.loadingPayment = false;
          });
        }
        
      },
      initiateMomo() {
        this.loadingPayment = true;
        axios
          .post(this.uri + "api/v3/loans/pezesha/user/repayment", {
            phoneNumber: this.paymentmodal.phone,
            Amount: this.paymentmodal.totalAmount,
            loan_id: this.loan.loan_id,
            user_id: this.loan.user_id,
            farmer_name: this.profile.name,
          },{
            headers: { Authorization: "Bearer " + this.user },
        })
          .then( (response)=> {
            this.editingpayment = false;
            this.resetPaymentModal();
            this.$bvToast.toast('Successfully initiated. Ask user to enter pin.',{
                variant:'success',
                title:response.data.message
            })
            this.loadingPayment = false;
          })
          .catch(()=> {
            this.editingpayment = false;
            this.resetPaymentModal();
            this.$bvToast.toast(`Failed to initiate payment`,{
              variant:'danger',
              title:'Failed to initiate payment'
            })
            this.loadingPayment = false;
          });
      },

    },
  
    created() {
      bus.$on("showloandetails", (payload) => {
        this.checkUser(this);
        this.profile={};
        this.resetPaymentModal();
        this.loan = payload;

        this.admin = sessionStorage.getItem('admin');
        this.getUserPayments();
        this.getUserProfile();
        this.getUserHistory();
        
        this.switchtab(this.loan.tab ? this.loan.tab : "details");
        this.$bvModal.show("loan-details-modal");
        
      });
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .label {
    font-size: 1em;
    font-weight: 500;
    opacity: 0.75;
  }
  
  .val {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 12pt;
  }
  
  .loans-content {
    height: 90vh;
    max-height: 480pt;
    overflow: auto;
  }
  
  .tabs a {
    display: block;
    font-weight: 400;
    font-size: 1.2em;
    border-radius: 0pt;
    border-right: 4pt solid transparent;
    padding: 10pt 18pt;
    border-left: none;
    border-top: none;
    border-bottom: none;
    color: #666;
    cursor: pointer;
  }
  
  .tabs a.active {
    border-right-color: #3583c7;
    color: #3583c7;
    font-weight: bold;
    background-color: rgba(50, 78, 133, 0.1);
  }
  
  .left {
    background-color: rgba(230, 231, 233, 0.849);
  }
  
  .feather {
    position: relative;
    top: -1.7pt;
    vertical-align: middle;
    display: inline-block;
    height: 1em;
    margin-right: 3 px;
  }
  
  .app-card {
    .label {
      margin: 0;
      font-weight: 700;
      font-size: 10pt;
      opacity: 1;
    }
  
    .val {
      opacity: 0.75 !important;
      border-bottom: 1px dashed #999;
      padding-bottom: 5pt;
    }
  }
  
  .loan-summary {
    position: sticky;
    /*    background: #fff;
          box-shadow: 0 0 10pt rgba(0,0,0,0.1);*/
    top: 0;
    bottom: 0;
  
    .label {
      margin: 0;
      font-weight: 500;
      font-size: 1em;
      opacity: 1;
      color: #000;
    }
  
    .val {
      font-size: 1em;
      opacity: 0.75 !important;
      color: #2c3e50;
    }
  }
  
  .card-usage {
    background-color: #fff;
    padding: 10pt;
  
    border: 1px solid #aaa;
    border-left: 2px solid #87b7e7;
    border-radius: 3pt;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    p {
      font-weight: bold;
    }
    h5 {
      margin: 0;
      font-size: 1em;
    }
  }
  
  .m-title {
    font-size: 14pt;
    line-height: 25pt;
  }
  </style>
  