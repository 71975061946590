<template>
    <div>
      <b-modal
        ref="failed-logs-modal"
        body-class="mymodal-body"
        static-backdrop
        no-fade
        no-close-on-esc
        no-close-on-backdrop
        id="failed-logs-modal"
        size="xl"
        scrollable
        hide-header
        hide-footer
        title
      >
        <div class="row m-0">
          
          <div class="col p-0 position-relative">
            <div
              class="py-2 bg-white border-bottom"
              style="box-shadow:3px 3px 5pt rgba(0,0,0,.06); position:relative; z-index:50"
            >
              <div class="row m-0">
                <div class="col-12 col-md">
                  <h4 class="m-0 text-capitalize m-title">{{ tab }}</h4>
                </div>
                <div class="col-12 col-md-auto">
                  <button class="mr-2 light btn-menu">
                    <printer-icon />Print
                  </button>
                  <button @click="closeDetails" class="light btn-menu">
                    <x-icon></x-icon>Close
                  </button>
                </div>
              </div>
            </div>
            <div class="loans-content position-relative bg-light">
              <div class="tab-view" v-show="tab == 'details'" key="details">
                <div class="p-3 border">
                  <div class="row m-0">
                    <div class="col-lg-6 col-12">
                      <div class="row">
                        <div class="pt-3 col  text-center" v-show="loadingProfile">
                          <b-spinner variant="primary"></b-spinner>
                        </div>
                        <div v-show="loadingLimit" class="col-lg-6 col-12 pl-0">
                          Loading .... <b-spinner></b-spinner>
                        </div>
                        <div v-show="loanlimit.amount && !loadingLimit" class="col-lg-6 col-12 pl-0">
                          <div class="label">Principal</div>
                          <div class="val text-primary-dark">
                            {{ loanlimit.amount | addCommas }}
                          </div>
  
                          <div class="label">Total loan</div>
                          <div class="val">
                            {{
                              (loanlimit.amount + loanlimit.interest) | addCommas
                            }}
                            <span class="badge bage-success"
                              >{{ loanlimit.rate }}%</span
                            >
                          </div>
                        </div>
  

                      </div>
                    </div>
                  </div>
                </div>
                
  
                
              </div>
  

  
              <div
                class="tab-view bg-light p-0"
                v-show="tab == 'application'"
                key="application"
              >
                <div class="row m-0">
                  <div class="pt-3 col-12  text-center" v-show="loadingProfile">
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                  <div v-if="profile.error" class="px-3">
                  <table class="tb table table-secondary" v-if="profile.error.errors">
                    <thead>
                      <tr>
                        <th colspan="3">{{profile.error.message}}</th>
                      </tr>
                      <tr>
                        <th>Error Field</th>
                        <th></th>
                        <th class="text-right">Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in Object.keys(profile.error.errors)" :key="index">
                        <td colspan="2">{{ item }}</td>
                        <td class="text-right"><span v-for="(each,index) in profile.error.errors[item]" :key="index">{{
                         each
                          }}<span v-if="(index!==(profile.error.errors[item].length-1))">,nbsp;</span></span>
                          </td>
                      </tr>
                      <tr v-if="!(Object.keys(profile.error.errors).length > 0) && !loading">
                        <td colspan="2">No payments...</td>
                        <td></td>
                        <td>
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="2">Loading...</td>
                        <td></td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="tb table table-secondary" v-if="profile.error.detail">
                    <thead>
                      <tr>
                        <th colspan="3">{{profile.error.message}}</th>
                      </tr>
                      <tr>
                        <th>Error Field</th>
                        <th></th>
                        <th class="text-right">Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in profile.error.detail" :key="index">
                        <td colspan="2">{{ item.type }}</td>
                        <td class="text-right">{{item.msg}}
                          </td>
                      </tr>
                      <tr v-if="!(profile.error.detail.length > 0) && !loading">
                        <td colspan="2">No errors...</td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="2">Loading...</td>
                        <td></td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                  </table>

              </div>
                  <div v-show="!loadingProfile" class="col py-3">
                    <div class="card app-card mb-3">
                      <h4 class="stitle">Bio info</h4>
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <p class="label">Name</p>
                          <p class="val">{{ profile.farmer_name }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Age, Gender</p>
                          <p class="val">
                            {{ profile.farmer_dob | age }}, {{ profile.gender }}
                          </p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Phone</p>
                          <p class="val">{{ profile.contact | contact }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Email</p>
                          <p class="val">{{ profile.email }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Address</p>
                          <p class="val">
                            {{ profile.district }}, {{ profile.village }}
                          </p>
                          <p class="val">{{ profile.address }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">ID card</p>
                          <p class="val">
                            {{ profile.nin }}
                            <a @click="viewIdphoto = true" class="link-btn"
                              >View ID</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="card app-card mb-3">
                      <h4 class="stitle">Additional info</h4>
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <p class="label">Education level</p>
                          <p class="val">{{ profile.education }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Dependants</p>
                          <p class="val">{{ profile.number_of_dependants }}</p>
                        </div>
  
                        <div class="col-12 col-lg-6">
                          <p class="label">Main source of income</p>
                          <p class="val">{{ profile.other_income_source }}</p>
                        </div>
  

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="submodal text-center"
              v-show="viewIdphoto == true && tab == 'application'"
              key="829029899"
              style="width:300pt"
            >
              <div class="text-center d-block">
                <img
                  class="img-fluid"
                  :src="profile.nin_photo_url ? profile.nin_photo_url : ''"
                />
              </div>
              <button class="m-auto" @click="viewIdphoto = false">Close</button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  <script>
  import {
    XIcon,
    PrinterIcon,
  } from "vue-feather-icons";

  import Tables from "../../mixins/Tables";
  import { bus } from "../../main";
  
  import axios from "axios";
  
  export default {
    name: "LoanDetails",
    components: {
      XIcon,
      PrinterIcon,
    },
    mixins: [Tables],
  
    data() {
      return {
        loan: {
          time: "",
          due_date: "",
        },

        modalTitle: "Loan details",
        tab: "details",
        fetching: "loading...",
        loadingProfile: false,
        loadingLimit: false,
        transactions:[],
        uri: process.env["VUE_APP_BACKEND_URL"],
        user: null,
        loading: false,
        loadingHistory: false,
        success: false,
        score_details: {},
        failed: false,
        credit_score: null,
        interestRate: null,
        senddataingestion: false,
        editingapprove: false,
        approvesection: "",
        viewIdphoto: false,
        viewgardenmap: false,
        reason: null,
        response: null,
        history: [],
        profile: {},
        loanlimit: {},
        paymentMethods: [
          { key: "Select", value: null },
          { key: "Mobile", value: "mobile" },
          { key: "Cash", value: "cash" },
        ],
        interestRates: [
          { name: "Standard", value: "3.0" },
          { name: "Old farmers", value: "2.5" },
        ], //get interest rates
        paymentmodal: {
          /*payment model */
          id: 0,
          date: new Date(),
          method: "cash",
          produce: "",
          quantity: 0,
          units: "",
          unitPrice: 0,
          totalAmount: 0,
          phone: "",
        },
        dataloading: {
          "loading...": 0,
        },
        coopData: {},
        admin: false,
  
        payments: [],
        old: [{ date: "loading..", time: "", amount: 0 }],
        monitoringdata: [],
        usagedata: [],
      };
    },
  
    methods: {
      closeDetails() {
        this.$bvModal.hide("failed-logs-modal");
        bus.$emit("hidedetails");
      },
      reload() {
          this.getUserProfile()
      },
  
      switchtab(val) {
        this.tab = val;
      },
  
      newPayment() {
        this.senddataingestion = true;
        this.resetPaymentModal();
        this.paymentmodal.phone = this.profile.contact;
      },
  
      editPayment(index) {
        this.senddataingestion = true;
        this.resetPaymentModal();
        let pay = this.payments[index];
  
        /*set the payment modal to the clicked payment object*/
        this.paymentmodal.id = pay.id;
        this.paymentmodal.date = pay.date;
        this.paymentmodal.method = pay.method;
        this.paymentmodal.produce = pay.method;
        this.paymentmodal.quantity = pay.quantity;
        this.paymentmodal.unitPrice = pay.unitprice;
        this.paymentmodal.totalAmount = pay.amount;
        this.paymentmodal.units = "";
      },
  
      cancelPayment() {
        this.senddataingestion = false;
        this.resetPaymentModal();
      },
  
      resetPaymentModal() {
        this.paymentmodal.id = "0";
        this.paymentmodal.date = "";
        this.paymentmodal.method = "cash";
        this.paymentmodal.produce = "";
        this.paymentmodal.quantity = 0;
        this.paymentmodal.unitPrice = 0;
        this.paymentmodal.totalAmount = 0;
        this.paymentmodal.units = "";
      },
  


      getUserProfile() {
        this.loadingProfile = true;
  
        axios
          .get(this.uri + "api/v3/loans/pezesha/users/failed/single", {
            headers: { Authorization: "Bearer " + this.user },
            params: {
              log_id: this.user_doc.id
            },
          })
          .then((response) => {
            this.profile = response.data[0];
            this.loadingProfile = false;
          })
          .catch(() => {
            this.loadingProfile = false;
          });
      },

    },
  
    created() {
      bus.$on("showLogDetails", (payload) => {
        this.checkUser(this);
        this.profile = {};
        this.history = [];
        this.transactions = [];
        this.loanlimit = {};
        this.resetPaymentModal();
        this.user_doc = payload;
  
        this.admin = sessionStorage.getItem("admin");
  
        this.reload();
  
        this.switchtab("application");
        this.$bvModal.show("failed-logs-modal");
      });
    },
  };
  </script>
  <style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .label {
    font-size: 1em;
    font-weight: 500;
    opacity: 0.75;
  }
  
  .val {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 12pt;
  }
  
  .loans-content {
    height: 90vh;
    max-height: 480pt;
    overflow: auto;
  }
  
  .tabs a {
    display: block;
    font-weight: 400;
    font-size: 1.2em;
    border-radius: 0pt;
    border-right: 4pt solid transparent;
    padding: 10pt 18pt;
    border-left: none;
    border-top: none;
    border-bottom: none;
    color: #666;
    cursor: pointer;
  }
  
  .tabs a.active {
    border-right-color: #3583c7;
    color: #3583c7;
    font-weight: bold;
    background-color: rgba(50, 78, 133, 0.1);
  }
  
  .left {
    background-color: rgba(230, 231, 233, 0.849);
  }
  
  .feather {
    position: relative;
    top: -1.7pt;
    vertical-align: middle;
    display: inline-block;
    height: 1em;
    margin-right: 3 px;
  }
  
  .app-card {
    .label {
      margin: 0;
      font-weight: 700;
      font-size: 10pt;
      opacity: 1;
    }
  
    .val {
      opacity: 0.75 !important;
      border-bottom: 1px dashed #999;
      padding-bottom: 5pt;
    }
  }
  
  .loan-summary {
    position: sticky;
    /*    background: #fff;
          box-shadow: 0 0 10pt rgba(0,0,0,0.1);*/
    top: 0;
    bottom: 0;
  
    .label {
      margin: 0;
      font-weight: 500;
      font-size: 1em;
      opacity: 1;
      color: #000;
    }
  
    .val {
      font-size: 1em;
      opacity: 0.75 !important;
      color: #2c3e50;
    }
  }
  
  .card-usage {
    background-color: #fff;
    padding: 10pt;
  
    border: 1px solid #aaa;
    border-left: 2px solid #87b7e7;
    border-radius: 3pt;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    p {
      font-weight: bold;
    }
    h5 {
      margin: 0;
      font-size: 1em;
    }
  }
  
  .m-title {
    font-size: 14pt;
    line-height: 25pt;
  }
  </style>
  