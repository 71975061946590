<template>
  <div>
    <b-modal
      ref="user-details-modal"
      body-class="mymodal-body"
      static-backdrop
      no-fade
      no-close-on-esc
      no-close-on-backdrop
      id="user-details-modal"
      size="xl"
      scrollable
      hide-header
      hide-footer
      title
    >
      <div class="row m-0">
        <div class="col-3 left p-0">
          <div class="py-3 px-4">
            <h4>{{ profile.name }}</h4>
            <p>
              <chevron-down-icon />
            </p>
          </div>
          <div class="tabs" role="tablist">
            <a
              class="tab-link"
              :class="{ active: tab == 'details' }"
              @click="switchtab('details')"
              >Transaction details</a
            >
            <a
              class="tab-link"
              :class="{ active: tab == 'history' }"
              @click="switchtab('history')"
              >Loan History</a
            >
            <a
              class="tab-link"
              :class="{ active: tab == 'application' }"
              @click="switchtab('application')"
              >User profile</a
            >
          </div>
        </div>
        <div class="col p-0 position-relative">
          <div
            class="py-2 bg-white border-bottom"
            style="box-shadow:3px 3px 5pt rgba(0,0,0,.06); position:relative; z-index:50"
          >
            <div class="row m-0">
              <div class="col-12 col-md">
                <h4 class="m-0 text-capitalize m-title">{{ tab }}</h4>
              </div>
              <div class="col-12 col-md-auto">
                <button class="mr-2 light btn-menu">
                  <printer-icon />Print
                </button>
                <button @click="closeDetails" class="light btn-menu">
                  <x-icon></x-icon>Close
                </button>
              </div>
            </div>
          </div>
          <div class="loans-content position-relative bg-light">
            <div class="tab-view" v-show="tab == 'details'" key="details">
              <div class="p-3 border">
                <div class="row m-0">
                  <div class="col-lg-6 col-12">
                    <div class="row">
                      <div v-show="loadingLimit" class="col-lg-6 col-12 pl-0">
                        Loading .... <b-spinner></b-spinner>
                      </div>
                      <div v-show="loanlimit.amount && !loadingLimit" class="col-lg-6 col-12 pl-0">
                        <div class="label">Principal</div>
                        <div class="val text-primary-dark">
                          {{ loanlimit.amount | addCommas }}
                        </div>

                        <div class="label">Total loan</div>
                        <div class="val">
                          {{
                            (loanlimit.amount + loanlimit.interest) | addCommas
                          }}
                          <span class="badge bage-success"
                            >{{ loanlimit.rate }}%</span
                          >
                        </div>
                      </div>

                      <div v-show="loanlimit.amount && !loadingLimit" class="col-lg-6 col-12">
                        <div class="label"><clock-icon />Payment period</div>
                        <div class="val">
                          {{ loanlimit.duration }}
                          <small>day(s) from now</small>
                        </div>
                      </div>
                      <div  v-show="!loanlimit.amount && !loadingLimit" class="col-lg-12 col-12"  >
                        <p class="text-danger">Does NOT qualify for a loan.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0 border-left">
                    <table class="tb table table-light">
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <td>Previous loans No.</td>
                          <td class="text-right">
                            {{ history.length }}
                            <small></small>
                          </td>
                        </tr>
                        <tr >
                          <td>Transactions No.</td>
                          <td class="text-right">
                            {{ transactions.length }}
                            <small></small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="transactions.length>0" class="row m-0">
                <div class="col">
                  <h4 class="stitle mb-0 pt-2">
                    User Transactions ({{ transactions.length }})
                  </h4>
                </div>
                <div class="col-auto pb-2 pt-1">
                  <button
                    v-show="!loading"
                    class="btn-menu btn-primary"

                    @click="newPayment()"
                  >
                    <plus-icon />Send Data
                  </button>
                  &nbsp;
                  <button v-show="!loading" class="btn-menu" @click="reload">
                    <b-icon-arrow-clockwise /> Reload
                  </button>
                </div>
              </div>

              <div v-if="payments" class="px-3">
                <table class="tb table table-secondary">
                  <thead>
                    <tr>
                      <th>Transaction time</th>
                      <th></th>
                      <th class="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in transactions" :key="index">
                      <td colspan="2">{{ item.transaction_time | datetime }}</td>
                      <td class="text-right">{{ item.face_amount | addCommas }}</td>
                    </tr>
                    <tr v-if="!(transactions.length > 0) && !loading">
                      <td colspan="2">No payments...</td>
                      <td></td>
                      <td>
                      </td>
                    </tr>
                    <tr v-if="loading">
                      <td colspan="2">Loading...</td>
                      <td></td>
                      <td>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-view" v-show="tab == 'history'" key="history">
              <div class="container">
                <div class="p-3 row">
                  <h4 class="stitle col-sm-12">Loan History</h4>
                  <table
                    v-if="!loadingHistory"
                    class="table table-secondary col-sm-12"
                  >
                    <thead>
                      <tr>
                        <th class="br">Date</th>
                        <th class="br text-right">Principal</th>
                        <th class="br text-right">Total</th>
                        <th class="br text-right">Paid</th>
                        <th class="br text-right">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in history" :key="index">
                        <td class="text-left">{{ item.date | moment }}</td>
                        <td class="text-right">
                          {{ item.amount | addCommas }}
                        </td>
                        <td class="text-right">{{ item.total | addCommas }}</td>
                        <td class="text-right">{{ item.paid | addCommas }}</td>
                        <td class="text-right">
                          {{ item.balance | addCommas }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="py-2" v-if="loadingHistory">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-view bg-light p-0"
              v-show="tab == 'application'"
              key="application"
            >
              <div class="row m-0">
                <div class="col  text-center" v-show="loadingProfile">
                  <b-spinner variant="primary"></b-spinner>
                </div>
                <div v-show="!loadingProfile" class="col py-3">
                  <div class="card app-card mb-3">
                    <h4 class="stitle">Bio info</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="label">Name</p>
                        <p class="val">{{ profile.name }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Age, Gender</p>
                        <p class="val">
                          {{ profile.farmer_dob | age }}, {{ profile.gender }}
                        </p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Phone</p>
                        <p class="val">{{ profile.contact | contact }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Email</p>
                        <p class="val">{{ profile.email }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Address</p>
                        <p class="val">
                          {{ profile.district }}, {{ profile.village }}
                        </p>
                        <p class="val">{{ profile.address }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">ID card</p>
                        <p class="val">
                          {{ profile.nin }}
                          <a @click="viewIdphoto = true" class="link-btn"
                            >View ID</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card app-card mb-3">
                    <h4 class="stitle">Additional info</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="label">Education level</p>
                        <p class="val">{{ profile.education }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Dependants</p>
                        <p class="val">{{ profile.number_of_dependants }}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Main source of income</p>
                        <p class="val">{{ profile.other_income_source }}</p>
                      </div>

                      <div v-show="!loadingLimit" class="col-12 col-lg-6">
                        <p class="label">Loan Eligibility</p>
                        <p class="val">
                          <span v-if="loanlimit.amount">
                            <check-circle-icon class="text-primary" />Yes
                          </span>
                          <span v-if="!loanlimit.amount">
                            <x-circle-icon class="text-danger" />No
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="loadingLimit" class="col-3 pl-0">
                  Loading Loan Limit ...
                  <b-spinner variant="primary"></b-spinner>
                </div>
                <div v-show="!loadingLimit" class="col-3 pl-0">
                  <div class="loan-summary px-2 py-3">
                    <p v-if="admin" class="label">Loan limit</p>
                    <p v-if="admin" class="val">
                      {{ loanlimit.amount | addCommas }}
                    </p>

                    <p v-if="admin" class="label">Eligibility</p>
                    <p v-if="admin" class="val">
                      <span v-if="loanlimit.amount">
                        <check-circle-icon class="text-primary" />Yes
                      </span>
                      <span v-if="!loanlimit.amount">
                        <x-circle-icon class="text-danger" />No
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div
              class="submodal"
              v-show="senddataingestion == true && tab == 'details'"
              key="8290298"
            >
              <form @submit.prevent>
                <div classs="p-3">
                  <label class="control-label"
                    >Are you sure you want to manually send &nbsp;
                    <span class="text-success">{{ profile.name }}</span>'s data?
                  </label>
                  <div class="py-2" v-if="!loadingPayment">
                    <button class="mr-2" @click="cancelPayment()">
                      Cancel
                    </button>
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right"
                      @click="sendUserTransactions"
                      >Send Data</b-button
                    >
                  </div>
                  <div class="py-2" v-if="loadingPayment">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                </div>
              </form>
            </div>
          </transition>

          <div
            class="submodal text-center"
            v-show="viewIdphoto == true && tab == 'application'"
            key="829029899"
            style="width:300pt"
          >
            <div class="text-center d-block">
              <img
                class="img-fluid"
                :src="profile.nin_photo_url ? profile.nin_photo_url : ''"
              />
            </div>
            <button class="m-auto" @click="viewIdphoto = false">Close</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  ChevronDownIcon,
  XIcon,
  PrinterIcon,
  CheckCircleIcon,
  ClockIcon,
  PlusIcon,
  XCircleIcon,
} from "vue-feather-icons";
import Tables from "../../mixins/Tables";
import { bus } from "../../main";

import axios from "axios";

export default {
  name: "LoanDetails",
  components: {
    ChevronDownIcon,
    XIcon,
    PrinterIcon,
    CheckCircleIcon,
    ClockIcon,
    PlusIcon,
    XCircleIcon,
  },
  mixins: [Tables],

  data() {
    return {
      loan: {
        time: "",
        due_date: "",
      },
      modalTitle: "Loan details",
      tab: "details",
      fetching: "loading...",
      loadingPayment: false,
      loadingProfile: false,
      loadingLimit: false,
      transactions:[],
      uri: process.env["VUE_APP_BACKEND_URL"],
      user: null,
      loading: false,
      loadingHistory: false,
      success: false,
      score_details: {},
      failed: false,
      credit_score: null,
      interestRate: null,
      senddataingestion: false,
      editingapprove: false,
      approvesection: "",
      viewIdphoto: false,
      viewgardenmap: false,
      reason: null,
      response: null,
      history: [],
      profile: {},
      loanlimit: {},
      paymentMethods: [
        { key: "Select", value: null },
        { key: "Mobile", value: "mobile" },
        { key: "Cash", value: "cash" },
      ],
      interestRates: [
        { name: "Standard", value: "3.0" },
        { name: "Old farmers", value: "2.5" },
      ], //get interest rates
      paymentmodal: {
        /*payment model */
        id: 0,
        date: new Date(),
        method: "cash",
        produce: "",
        quantity: 0,
        units: "",
        unitPrice: 0,
        totalAmount: 0,
        phone: "",
      },
      dataloading: {
        "loading...": 0,
      },
      coopData: {},
      admin: false,

      payments: [],
      old: [{ date: "loading..", time: "", amount: 0 }],
      monitoringdata: [],
      usagedata: [],
    };
  },

  methods: {
    closeDetails() {
      this.$bvModal.hide("user-details-modal");
      bus.$emit("hidedetails");
    },
    reload() {
      Promise.all([
        this.getUserProfile(),
        this.getUserTransactions(),
        this.getLoanLimit(),
        this.getUserHistory(),
      ]).then(() => null);
    },

    switchtab(val) {
      this.tab = val;
    },

    newPayment() {
      this.senddataingestion = true;
      this.resetPaymentModal();
      this.paymentmodal.phone = this.profile.contact;
    },

    editPayment(index) {
      this.senddataingestion = true;
      this.resetPaymentModal();
      let pay = this.payments[index];

      /*set the payment modal to the clicked payment object*/
      this.paymentmodal.id = pay.id;
      this.paymentmodal.date = pay.date;
      this.paymentmodal.method = pay.method;
      this.paymentmodal.produce = pay.method;
      this.paymentmodal.quantity = pay.quantity;
      this.paymentmodal.unitPrice = pay.unitprice;
      this.paymentmodal.totalAmount = pay.amount;
      this.paymentmodal.units = "";
    },

    cancelPayment() {
      this.senddataingestion = false;
      this.resetPaymentModal();
    },

    resetPaymentModal() {
      this.paymentmodal.id = "0";
      this.paymentmodal.date = "";
      this.paymentmodal.method = "cash";
      this.paymentmodal.produce = "";
      this.paymentmodal.quantity = 0;
      this.paymentmodal.unitPrice = 0;
      this.paymentmodal.totalAmount = 0;
      this.paymentmodal.units = "";
    },

    getUserHistory() {
      this.loadingHistory = true;

      axios
        .get(this.uri + "api/v3/loans/pezesha/user/history", {
          headers: { Authorization: "Bearer " + this.user },
          params: {
            user_id: this.user_doc.customer_id,
          },
        })
        .then((response) => {
          this.history = response.data;
          this.loadingHistory = false;
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            variant: "danger",
            title: "Failed to load history",
          });
          this.loadingHistory = false;
        });
    },
    getLoanLimit() {
      this.loadingLimit = true;

      axios
        .get(this.uri + "api/v3/loans/pezesha/user/loan-limit", {
          headers: { Authorization: "Bearer " + this.user },
          params: {
            user_id: this.user_doc.merchant_id,
          },
        })
        .then((response) => {
          this.loanlimit = response.data.data ? response.data.data : {};
          this.loadingLimit = false;
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            variant: "danger",
            title: "Failed to load limit",
          });
          this.loadingLimit = false;
        });
    },
    getUserTransactions() {
      this.loadingTransactions = true;

      axios
        .get(this.uri + "api/v3/loans/pezesha/user/transactions", {
          headers: { Authorization: "Bearer " + this.user },
          params: {
            user_id: this.user_doc.merchant_id,
          },
        })
        .then((response) => {
          this.transactions = response.data;
          this.loadingTransactions = false;
        })
        .catch((error) => {
          this.loadingTransactions = false;
          this.$bvToast.toast(error.response.data.message, {
            variant: "danger",
            title: "Failed to load transactions.",
          });
          
        });
    },
    getUserProfile() {
      this.loadingProfile = true;

      axios
        .get(this.uri + "api/v3/loans/pezesha/user/", {
          headers: { Authorization: "Bearer " + this.user },
          params: {
            user_id: this.user_doc.merchant_id,
          },
        })
        .then((response) => {
          this.profile = response.data[0];
          this.loadingProfile = false;
        })
        .catch(() => {
          this.loadingProfile = false;
        });
    },
    sendUserTransactions() {
      this.loadingPayment = true;
      axios
        .post(
          this.uri + "api/v3/loans/pezesha/user/ingest",
          {
            user_id: this.user_doc.merchant_id,
          },
          {
            headers: { Authorization: "Bearer " + this.user },
          }
        )
        .then((response) => {
          this.senddataingestion = false;
          this.resetPaymentModal();
          this.$bvToast.toast("Successfully queued.", {
            variant: "success",
            title: response.data.message,
          });
          this.loadingPayment = false;
        })
        .catch(() => {
          this.senddataingestion = false;
          this.resetPaymentModal();
          this.$bvToast.toast(`Failed to queue data transfer`, {
            variant: "danger",
            title: "Failed to initiate ingestion",
          });
          this.loadingPayment = false;
        });
    },
  },

  created() {
    bus.$on("showUserDetails", (payload) => {
      this.checkUser(this);
      this.profile = {};
      this.history = [];
      this.transactions = [];
      this.loanlimit = {};
      this.resetPaymentModal();
      this.user_doc = payload;

      this.admin = sessionStorage.getItem("admin");

      this.reload();

      this.switchtab(this.loan.tab ? this.loan.tab : "details");
      this.$bvModal.show("user-details-modal");
    });
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.label {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.75;
}

.val {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 12pt;
}

.loans-content {
  height: 90vh;
  max-height: 480pt;
  overflow: auto;
}

.tabs a {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  border-radius: 0pt;
  border-right: 4pt solid transparent;
  padding: 10pt 18pt;
  border-left: none;
  border-top: none;
  border-bottom: none;
  color: #666;
  cursor: pointer;
}

.tabs a.active {
  border-right-color: #3583c7;
  color: #3583c7;
  font-weight: bold;
  background-color: rgba(50, 78, 133, 0.1);
}

.left {
  background-color: rgba(230, 231, 233, 0.849);
}

.feather {
  position: relative;
  top: -1.7pt;
  vertical-align: middle;
  display: inline-block;
  height: 1em;
  margin-right: 3 px;
}

.app-card {
  .label {
    margin: 0;
    font-weight: 700;
    font-size: 10pt;
    opacity: 1;
  }

  .val {
    opacity: 0.75 !important;
    border-bottom: 1px dashed #999;
    padding-bottom: 5pt;
  }
}

.loan-summary {
  position: sticky;
  /*    background: #fff;
        box-shadow: 0 0 10pt rgba(0,0,0,0.1);*/
  top: 0;
  bottom: 0;

  .label {
    margin: 0;
    font-weight: 500;
    font-size: 1em;
    opacity: 1;
    color: #000;
  }

  .val {
    font-size: 1em;
    opacity: 0.75 !important;
    color: #2c3e50;
  }
}

.card-usage {
  background-color: #fff;
  padding: 10pt;

  border: 1px solid #aaa;
  border-left: 2px solid #87b7e7;
  border-radius: 3pt;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  p {
    font-weight: bold;
  }
  h5 {
    margin: 0;
    font-size: 1em;
  }
}

.m-title {
  font-size: 14pt;
  line-height: 25pt;
}
</style>
