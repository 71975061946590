<template>
  <div>
    <b-modal
      ref="credits-details-modal"
      body-class="mymodal-body"
      static-backdrop
      no-fade
      no-close-on-esc
      no-close-on-backdrop
      id="credits-details-modal"
      size="xl"
      scrollable
      hide-header
      hide-footer
      title="Using Component Methods"
    >
      <div class="row m-0">
        <div class="col-3 left p-0">
          <div class="py-3 px-4 mb-5" style="border-bottom:1px solid #aaa;">
            <h4>{{item.farmer_name}}</h4>
            <p>
              <phone-icon />
              {{item.contact | contact}}
            </p>
          </div>
          <div role="tablist " class="text-center">
            <div class="item-x">
              <h5 class="label">Credits Balance</h5>
              <h2 class="val font-weight-bold">{{item.balance | addCommas}}</h2>
            </div>

            <div class="item-x">
              <h5 class="label">Total credits used</h5>
              <h2 class="val text-dark">{{item.used | addCommas}}</h2>
            </div>
            <div class="item-x">
              <h5 class="label">Total credits bought</h5>
              <h2 class="val text-dark">{{parseInt(item.balance) + parseInt(item.used) | addCommas}} </h2>
            </div>
            <hr />
            <button class="btn-success mx-auto" @click="editing=true">
              <plus-icon />Add Credits
            </button>
          </div>
        </div>
        <div class="col p-0 position-relative">
          <div
            class="py-2 bg-white border-bottom"
            style="box-shadow:3px 3px 5pt rgba(0,0,0,.06); position:relative; z-index:50"
          >
            <div class="row m-0">
              <div class="col-12 col-md">
                <div class="tabs">
                  <a
                    class="tab-link"
                    :class="{active: tab=='usage'}"
                    @click="switchtab('usage')"
                  >Credit Usage</a>
                  <a
                    class="tab-link"
                    :class="{active: tab=='purchase'}"
                    @click="switchtab('purchase')"
                  >Purchase History</a>
                </div>
              </div>
              <div class="col-12 col-md-auto">
                <button class="mr-2 light btn-menu">
                  <printer-icon />Print
                </button>
                <button @click="closeDetails" class="light btn-menu">
                  <x-icon></x-icon>Close
                </button>
              </div>
            </div>
          </div>
          <div class="loans-content position-relative bg-light">
            <div class="tab-view" v-show="tab=='usage'" key="usage">
              <div class="row m-0">
                <div class="col">
                  <h4 class="stitle mb-0 pt-3">Credit usage history</h4>
                </div>
                <div class="col-auto pb-2 pt-1">
                  <input class="form-control" placeholder="Date period" />
                </div>
              </div>

              <div class="px-3">
                <b-container v-show="loading">
                  <b-row class="d-flex justify-content-center mb-3">
                    <b-spinner class="loader"
                      style="width: 3rem; height: 3rem;"
                      label="Large Spinner"
                    ></b-spinner>
                  </b-row>
                </b-container>
                <table v-if="!loading" class="table table-light">
                  <thead>
                    <tr>
                      <th width="1pt"></th>
                      <th>Reason</th>
                      <th>Quantity</th>
                      <th class="text-right pr-5">Credits Used</th>
                      <th class>Date</th>
                      <th class>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in creditUsage" :key="index">
                      <td>{{index + 1}}</td>
                      <td>{{item.category}}</td>
                      <td class>{{item.items}} </td>
                      <td class="text-right pr-5 txt-primary font-weight-bold">{{item.used | addCommas}}</td>
                      <td class>{{ item.date[0] | moment}}</td>
                      <td class>{{ item.date[1] | time}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-view" v-show="tab=='purchase'" key="purchase">
              <div class="px-3">
                <div class="row">
                  <div class="col">
                    <h4 class="stitle mb-0 pt-3">Credit purchase history</h4>
                  </div>
                  <div class="col-auto pb-2 pt-1">
                    <input class="form-control" placeholder="Date period" />
                  </div>
                </div>
                <b-container v-show="loadingPurchase">
                  <b-row class="d-flex justify-content-center mb-3">
                    <b-spinner class="loader"
                      style="width: 3rem; height: 3rem;"
                      label="Large Spinner"
                    ></b-spinner>
                  </b-row>
                </b-container>
                <table v-if="!loadingPurchase" class="table table-light">
                  <thead>
                    <tr>
                      <th width="1pt"></th>
                      <th>Date</th>
                      <th class>Time</th>
                      <th class="text-right pr-5">Credits Purchased</th>
                      <th class>Method</th>
                      <th class></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in creditPurchase" :key="index">
                      <td>{{index + 1}}</td>
                      <td>{{item.date[0] | moment}}</td>
                      <td>{{item.date[1] | time}}</td>
                      <td class="text-right pr-5 txt-primary font-weight-bold">{{item.bought | addCommas}}</td>
                      <td class>{{item.category}}</td>
                      <!-- <td class>{{item.status}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="submodal" v-show="editing == true" key="8290298">
            <div>
              <div id="reject-loan">
                <h4 class="title mb-3">Add credits</h4>
                <p>
                  Add credits to
                  <b>{{item.name}}'s</b> account
                </p>
                <label class="control-label">Enter credits</label>
                <input class="form-control" rows="4" />

                <div class="py-2">
                  <button class="mr-2" @click="editing=false">Cancel</button>
                  <button class="btn-success">Top up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { XIcon, PrinterIcon, PlusIcon, PhoneIcon } from "vue-feather-icons";
import Tables from "../mixins/Tables";
import axios from "axios";

import { bus } from "../main";

export default {
  name: "CreditDetails",
  components: {
    XIcon,
    PrinterIcon,
    PlusIcon,
    PhoneIcon
  },
mixins: [Tables],
  data() {
    return {
      item: {},

      tab: "usage",

      editing: false,
      loadingPurchase:false,
      loading:false,
      totalCreditsBalance: 673,
      totalCreditsUsed: 388,
      uri:process.env['VUE_APP_BACKEND_URL'],
      totalCreditsBought: 399,
      user: null,
      creditItem: {},
      creditUsage: [
        {
          id: 782990,
          name: "Spray",
          units: "ltrs",
          quantity: "23",
          amount: "35000",
          date: "3 June 2020"
        }
      ],
      creditPurchase: [
        {
          id: 782990,
          credits: "20000",
          method: "Mobile money",
          status: "",
          date: "3 June 2020"
        },
        {
          id: 6689390,
          credits: "20000",
          method: "Loan",
          status: "",
          date: "3 June 2020"
        },

        {
          id: 8382992,
          credits: "20000",
          method: "Ezyagric team",
          status: "",
          date: "3 June 2020"
        }
      ]
    };
  },

  methods: {
    closeDetails() {
      this.$bvModal.hide("credits-details-modal");
      bus.$emit("hide-credit-details");
    },

    switchtab(val) {
      //
      this.tab = val;
      //                alert(val)
    },

    // editCredits(index) {
    //   this.editing = true;
    //   this.resetPaymentModal();
    // },

    cancelTopup() {
      this.editing = false;
      this.resetPaymentModal();
    },

    resetPaymentModal() {
      this.paymentmodal.id = "0";
      this.paymentmodal.date = "";
      this.paymentmodal.method = "cash";
      this.paymentmodal.produce = "";
      this.paymentmodal.quantity = 0;
      this.paymentmodal.unitPrice = 0;
      this.paymentmodal.totalAmount = 0;
      this.paymentmodal.units = "";
    },
    getUsage(dataObj){
      dataObj.loading = true;
      dataObj.creditUsage = [];
      // dataObj.payments = dataObj.old;

      axios
        .get(dataObj.uri + "api/v2/credit/used/user", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            user_id:dataObj.item.farmer_id
          },
        })
        .then(function (response) {
          // // console.log(response);

          dataObj.creditUsage = response.data.message.filter(function (el) {
            return el != null;
          });
          // dataObj.loan.balance = response.data.results[0].balance;
          dataObj.loading = false;
        })
        .catch(function () {
          dataObj.payments = dataObj.old;
          dataObj.loading = false;
        });
    },
    getPurchase(dataObj){
      dataObj.loadingPurchase = true;
      // dataObj.payments = dataObj.old;
      dataObj.creditPurchase = [];


      axios
        .get(dataObj.uri + "api/v2/credit/bought/user", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            user_id:dataObj.item.farmer_id
          },
        })
        .then(function (response) {
          // // console.log(response);

          dataObj.creditPurchase = response.data.message.filter(function (el) {
            return el != null;
          });
          // dataObj.loan.balance = response.data.results[0].balance;
          dataObj.loadingPurchase= false;
        })
        .catch(function () {
          dataObj.payments = dataObj.old;
          dataObj.loadingPurchase = false;
        });
    }
  },
  created() {
    bus.$on("show-credit-details", payload => {
      // handle event
      // alert(45);
      this.checkUser(this);
      this.item = payload;
      this.$bvModal.show("credits-details-modal");
      this.getUsage(this);
      this.getPurchase(this);
      // console.log(payload);
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.label {
  font-size: 1em !important;
  font-weight: 500;
  opacity: 0.75;
}

.val {
  font-size: 2.2em;
  font-weight: 400;
  margin-bottom: 20pt;
  color: #105fb9;
}

.loans-content {
  height: 90vh;
  max-height: 480pt;
  overflow: auto;
}

.tabs a {
  transition-duration: 0.1s;
  display: inline-block;
  font-weight: 400;
  font-size: 1em;
  border-radius: 20pt;
  background-color: rgba(50, 78, 133, 0.1);
  padding: 3pt 18pt;
  border-left: none;

  margin-right: 10pt;
  border-top: none;
  border-bottom: none;
  color: #666;
  cursor: pointer;
}

.tabs a.active {
  border-right-color: #3583c7;
  color: #fff;
  background-color: #206cad;

  font-weight: bold;
  //   background-color: rgba(50, 78, 133, 0.1);
}

.left {
  background-color: rgba(230, 231, 233, 0.849);
}

.feather {
  position: relative;
  top: -1.7pt;
  vertical-align: middle;
  display: inline-block;
  height: 1em;
  margin-right: 3 px;
}

.app-card {
  .label {
    margin: 0;
    font-weight: 700;
    font-size: 10pt;
    opacity: 1;
  }

  .val {
    opacity: 0.75 !important;
    border-bottom: 1px dashed #999;
    padding-bottom: 5pt;
  }
}

.loan-summary {
  position: sticky;
  /*    background: #fff;
        box-shadow: 0 0 10pt rgba(0,0,0,0.1);*/
  top: 0;
  bottom: 0;

  .label {
    margin: 0;
    font-weight: 500;
    font-size: 1em;
    opacity: 1;
    color: #000;
  }

  .val {
    font-size: 1em;
    opacity: 0.75 !important;
    color: #2c3e50;
  }
}

.card-usage {
  background-color: #fff;
  padding: 10pt;

  border: 1px solid #aaa;
  border-left: 2px solid #87b7e7;
  border-radius: 3pt;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  p {
    font-weight: bold;
  }
  h5 {
    margin: 0;
    font-size: 1em;
  }
}
</style>
