<template>
  <div class="px-2">
    <div class="row mx-0 my-2 filter">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Loans Usage</h1>
        </div>
      </div>
      <div class="col-auto">
        <loan-filter @updateFilter="updatefilter" :filters="filters" />
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0">
        <div class="card-header p-2">
          <div class="row">
            <season-filter @updateFilter="updatefilter" :filters="seasonfilter" />
            <partner-filter @updateFilter="updatefilter" :filters="coopfilter" />
            <div class="col-auto px-0">
              <input
                class="search form-control py-0 m-0"
                placeholder="search"
                v-model="searchQuery"
                @keyup="onSearch"
              />
            </div>
            <div class="col-auto">
              <export-options
                page="LoansUsage"
                :parameters="parameters"
                :url="pageUrl"
                :loading="loading"
              />
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-holder" ref="holder" :style="holderstyles">
            <table class="w-100 table-stripes table h" ref="table">
              <thead>
                <th></th>
                <th style="width: 32%">Farmer</th>
                <th style="width: 32%">Application Inputs</th>
                <th style="width: 32%">Inputs Purchased</th>
              </thead>
              <tbody>
                <tr v-for="(loan, index) in loans" :key="index">
                  <td colspan="4">
                    <div class="row">
                      <div style="width: 5%">{{ index + 1 }}</div>
                      <div style="width: 94%">
                        <div class="row">
                          <div class="col-sm-4 no-margin">
                            <div class="row">
                              <div class="col-sm-12">
                                <b class="txt-primary">{{
                                  loan.farmer_name | capitalize
                                }}</b>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-4">Contact</div>
                              <div class="col-sm-4">Season</div>
                              <div class="col-sm-4">Loan Amount (UGX)</div>
                            </div>
                            <div class="row">
                              <div class="col-sm-4"><small>{{ loan.contact }}</small></div>
                              <div class="col-sm-4"><small>{{ loan.season }}</small></div>
                              <div class="col-sm-4">
                                <small><b>{{
                                  (loan.amount ? loan.amount : 0) | addCommas
                                }}</b></small>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4 no-margin">
                            <table
                              v-for="(item, i_index) in loan.application_inputs"
                              :key="i_index"
                              class="table table-secondary"
                            >
                              <thead>
                                <tr>
                                  <th colspan="4" class="text-center br">
                                    <b>Application Date:</b
                                    ><i>{{ item.date | moment }}</i>
                                  </th>
                                </tr>

                                <tr>
                                  <th class="br">Item(s)</th>
                                  <th class>Categories</th>
                                  <th class="br">Qty</th>
                                  <th class="br">Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(product, p_index) in item.items"
                                  :key="p_index"
                                >
                                  <td>{{ product }}</td>
                                  <td>
                                    {{
                                      item.categories
                                        ? item.categories[p_index]
                                        : ""
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.quantities
                                        ? item.quantities[p_index]
                                        : ""
                                    }}
                                    <small></small>
                                  </td>
                                  <td class="text-right">
                                    {{ item.cost ? item.cost[p_index] : "" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-4 no-margin">
                            <table
                              v-for="(order, o_index) in loan.order_items"
                              :key="o_index"
                              class="table table-secondary"
                            >
                              <thead>
                                <tr>
                                  <th colspan="3">
                                    <b>Order Date:</b
                                    ><i>{{ order.date | moment }}</i>
                                  </th>
                                </tr>

                                <tr>
                                  <th class="br">ITEM</th>
                                  <th class="br">QUANTITY</th>
                                  <th class="br">AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    orderProduct, oi_index
                                  ) in order.items"
                                  :key="oi_index"
                                >
                                  <td>{{ orderProduct.product }}</td>
                                  <td>
                                    {{
                                      orderProduct.category
                                        ? orderProduct.category
                                        : ""
                                    }}
                                    <small></small>
                                  </td>
                                  <td class="text-right">
                                    {{
                                      orderProduct.unit ? orderProduct.unit : ""
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="6" style="background: white">
                    <infinite-loading
                      :identifier="infiniteId"
                      @infinite="getActiveLoans"
                    ></infinite-loading>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-auto">
              <span class="txt-primary"
                >LOAN USAGE BASED ON APPROVED LOANS GROUPED BY SEASON</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader :isLoading="loading" />
  </div>
</template>

<script>
import Tables from "../mixins/Tables";

import LoanFilter from "../components/LoanFilter";
import SeasonFilter from "../components/SeasonFilter";
import PartnerFilter from "../components/PartnerFilter";
import ExportOptions from "../components/ExportOptions";
import Loader from "../components/Loader";
import axios from "axios";

export default {
  name: "LoansApproved",
  components: {
    LoanFilter,
    SeasonFilter,
    PartnerFilter,
    ExportOptions,
    Loader,
  },
  props: {
    maID: String,
  },
  mixins: [Tables],
  data() {
    return {
      loans: [],
      totalLoanAmount: 0,
      totalLoanBalance: 0,
      uri: process.env["VUE_APP_BACKEND_URL"],
      user: null,
      holderstyles: {
        height: "0px",
      },
      loanSelected: [],
      filters: {
        district: "All districts",
        partner: "",
        dates: {
          start: new Date(),
          end: new Date(),
        },
      },
      coopfilter: {
        farmerType: "",
        cooperative: "",
      },
      seasonfilter: {
        season: "",
        page: "All Loans Approved",
      },
      page: 1,
      pageUrl: process.env["VUE_APP_BACKEND_URL"] + "api/v2/loans/usage/",
      searchQuery: "",
      infiniteId: +new Date(),
      parameters: {},
      export_: false,
    };
  },
  mounted: function () {
    this.checkUser(this);

    this.pag = 1;
    this.$nextTick(function () {
      this.setTableHeight("holder", this.holderstyles);
    });
  },

  methods: {
    showLoanDetails(index, dataObj) {
      //alert(JSON.stringify(this.activeloans[index]))
      this.getLoan(index, dataObj);
    },

    getActiveLoans($state) {
      // dataObj.loading = true;
      let dataObj = this;
      (dataObj.parameters = {
        page: dataObj.page,
        name: dataObj.searchQuery,
        district:
          dataObj.filters.district === "All districts"
            ? ""
            : dataObj.filters.district,
        start: dataObj.formatDate(dataObj.filters.dates.start),
        end: dataObj.formatDate(dataObj.filters.dates.end),
        maID: dataObj.coopfilter.cooperative,
        farmerType: dataObj.coopfilter.farmerType,
        season: dataObj.seasonfilter.season,
        partner:
          dataObj.filters.partner === "All Partners"
            ? ""
            : dataObj.filters.partner,
      }),
        axios
          .get(dataObj.pageUrl, {
            headers: { Authorization: "Bearer " + dataObj.user },
            params: dataObj.parameters,
          })
          .then(function (response) {
            dataObj.page++;
            if (response.data.loan_requests.length >= 25) {
              dataObj.loans = dataObj.loans.concat(response.data.loan_requests);
              $state.loaded();
            } else if (response.data.loan_requests.length > 0) {
              dataObj.loans = dataObj.loans.concat(response.data.loan_requests);

              $state.loaded();
              throw false;
            } else {
              throw false;
            }
          })
          .catch(function (error) {
            console.log(error);
            // dataObj.loading = false;
            $state.complete();
          });
    },

    onSearch() {
      this.page = 1;
      (this.parameters = {
        page: this.page,
        name: this.searchQuery,
        district:
          this.filters.district === "All districts"
            ? ""
            : this.filters.district,
        start: this.formatDate(this.filters.dates.start),
        end: this.formatDate(this.filters.dates.end),
        maID: this.coopfilter.cooperative,
        farmerType: this.coopfilter.farmerType,
        season: this.seasonfilter.season,
        partner:
          this.filters.partner === "All Partners" ? "" : this.filters.partner,
      }),
        (this.loans = []);
      this.infiniteId += 1;
    },
  },
  events: {
    closeEvent: function () {
      //alert("close")
      this.export_ = false;
    },
  },
  watch: {
    loanSelected() {
      // console.log('change', this.loanSelected.status);
    },

    filters: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
    coopfilter: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
    seasonfilter: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dot {
  height: 10pt;
  width: 10pt;
  border-radius: 10pt;
  background-color: #e8e8e8;
  display: inline-block;
}

.no-margin{
  margin-left: 0 !important; 
  margin-right: 0 !important;
}
.outer {
  display: flex;
  flex-wrap: wrap;

  /* For demo purposes */
  max-width: 600px;
  margin: 20px auto;
  /* border-left: 1px solid black; */
  border-top: 1px solid black;
}

.column {
  /* border-right: 1px solid black; */
  flex: 1 1 33.33%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.row-item {
  flex-basis: 100%;
  border-bottom: 1px solid black;
}
.index {
  border-bottom: 1px solid black;
}
.inner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.item {
  /* border-right: 1px solid black; */
  /* border-bottom: 1px solid black; */
  text-align: center;
}

.item.heading {
  font-weight: bold;
  border-bottom: 1px solid black;
  flex: 1 1 33.33%;
}

.item:not(.heading) {
  flex: 1 1 33.33%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fixed .narrow {
  flex: 1 1 20px;
}
</style>
