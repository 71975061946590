<template>
  <loader :isLoading="loading" />
</template>

<script>
import Loader from "../components/Loader";
export default {
  name: "UserLogin",
  components: { Loader },
  data() {
    return {
      loading:true,
      uri: process.env.VUE_APP_BACKEND_URL,
      login: process.env.VUE_APP_LOGIN_URL,
    };
  },
  methods: {
    redirectToLogin() {
      sessionStorage.clear();
      this.$router.push('/login')
    },
  },

  created() {
    this.redirectToLogin();
  },
};
</script>
