/* eslint-disable */ 
<template>
  <div class="col">
    <span class="btn">
      <select
        class="form-control"
        v-model="selector.season"
        style="border: none; background: inherit"
      >
        <option value>{{ filters.page }}</option>
        <option v-for="(item, index) in seasons" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </span>
  </div>
</template>

<script>
import axios from "axios";
import Tables from "../mixins/Tables";

export default {
  name: "SeasonFilter",
  props: {
    filters: {
      type: Object,
      default: () => {
        return {
          season: "",
          page: "",
        };
      },
    },
  },
  mixins: [Tables],
  data() {
    return {
      uri: process.env["VUE_APP_BACKEND_URL"],
      seasons: [],
      selector: {
        season:'',
      }
    };
  },
  methods: {
    getSeasons(dataObj) {
      dataObj.loading = true;
      axios
        .get(dataObj.uri + "api/v2/seasonfilter")
        .then((response) => {
          dataObj.loading = false;
          dataObj.seasons = response.data.seasons;
        })
        .catch((error) => {
          console.log(error);
          dataObj.loading = false;
        });
    },
    updateFilter() {
      this.$emit("updateFilter", this.selector);
    },
  },
  created() {
    this.checkUser(this);
    this.getSeasons(this);
  },
  watch:{
selector:{
  handler(){
    this.updateFilter();
  },
  deep:true
},}
  
};
</script>
