/* eslint-disable */ 
<template>
  <div class="col">
    <div class="row">
      <div class="col">
        <span class="btn">
          <select
            class="form-control"
            v-model="selector.farmerType"
            style="border: none; background: inherit"
          >
            <option value="">All farmers</option>
            <option
              v-for="(item, index) in farmerTypes"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <!-- <chevron-down-icon /> -->
        </span>
      </div>
      <div class="col">
        <span class="btn">
          <select
            class="form-control"
            v-model="selector.cooperative"
            style="border: none; background: inherit"
          >
            <option value="">All Master Agents</option>
            <option
              v-for="(item, index) in cooperatives"
              :key="index"
              :value="item.ma_id"
            >
              {{ item.ma_name }}
            </option>
          </select>
          <!-- <chevron-down-icon /> -->
        </span>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Tables from "../mixins/Tables";

export default {
  name: "PartnerFilter",
  props: {
    filters: {
      type: Object,
      default: () => {
        return {
          cooperative: "",
          farmerType: "",
          partners:""
        };
      },
    },
  },
  mixins: [Tables],
  data() {
    return {
      cooperatives: [],
      uri: process.env["VUE_APP_BACKEND_URL"],
      farmerTypes:['SmartPhone Farmers', 'Cooperative Farmers'],
      selector:{
        cooperative: "",
          farmerType: "",
          partners:""
      }
    };
  },
  methods: {
    getCooperatives() {
      this.loading = true;
      axios
        .get(this.uri + "api/v2/"+(this.filters.type?this.filters.type:'loans')+"/cooperatives-all", {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then((response)=>{
          this.cooperatives = response.data.all_coops;
          this.loading = false;
        })
        .catch( (error)=> {
          console.log(error);
          this.loading = false;
        });
    },

    updateFilter() {
      this.$emit("updateFilter", this.selector);
    },
  },
  created() {
    this.checkUser(this);
    this.getCooperatives();

    this.admin = sessionStorage.getItem("admin");
    // this.admin?this.getPartners(this):null;
  },
  watch:{


selector:{
  handler(){
    this.onSearch();
  },
  deep:true
},}
};
</script>
