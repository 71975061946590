

import Vue from 'vue';
import Router from 'vue-router';
import App from './App.vue';

//import custom css'
import './loans.scss'
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use(Router);


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)


import Login from './views/Login.vue';
import Auth from './views/Auth.vue';
import LoansActive from './views/LoansActive.vue';
import LoansApplication from './views/LoanApplications.vue';
import LoansDashboard from './views/LoansDashboard';
import LoansRejected from './views/LoansRejected.vue';
import LoansApproved from './views/LoansApproved.vue';
import LoansUsage from './views/LoansUsage.vue';
import LoansCleared from './views/LoansCleared.vue';
import LoanPayments from './views/LoanPayments.vue';
import AggLoans from './views/AggLoans.vue';
import AggOffers from './views/AggOffers.vue';
import AggUsers from './views/AggUsers.vue';
import AggUsersLogs from './views/AggUsersLogs.vue';
import CreditsDashboard from './views/CreditDashboard.vue';
import CreditsFarmers from './views/CreditsFarmers.vue';
import CreditsUsage from './views/CreditsUsage.vue';
import CreditsBought from './views/CreditsBought.vue';
import CreditsTransactions from './views/CreditsTransactions.vue';


import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading, { /* options */ });

import VCharts from 'v-charts'
Vue.use(VCharts)

window.axion = require('axios');
import VCalendar from 'v-calendar';
// import { contains } from 'jquery';
Vue.use(VCalendar);

const routes = [{
    path: '/',
    name: 'login',
    component: Login,
},
{
    path: '/login',
    name: 'auth',
    component: Auth,
},
{
    path: '/loans/active',
    component: LoansActive,
    name: 'active-loans'
},
{
    path: '/loans/usage',
    component: LoansUsage,
    name: 'loan-usage'
},
{
    path: '/loans/dashboard',
    component: LoansDashboard,
    name: 'loan-dashboard'
},
{
    path: '/loans/approved',
    component: LoansApproved,
    name: 'loan-approved'
},
{
    path: '/loans/applications',
    component: LoansApplication,
    name: 'loan-applications'
},
{
    path: '/loans/cleared',
    component: LoansCleared,
    name: 'loans-cleared'
}, 
{
    path: '/loans/payments',
    component: LoanPayments,
    name: 'loan-payments'
}, 
{
    path: '/loans/rejected',
    component: LoansRejected,
    name: 'loans-rejected'
},
{
    path: '/loans/pezesha',
    component: AggLoans,
    name: 'pezesha-loans'
},
{
    path: '/loans/pezesha/users',
    component: AggUsers,
    name: 'pezesha-users'
},
{
    path: '/loans/pezesha/offers',
    component: AggOffers,
    name: 'pezesha-users'
},
{
    path: '/loans/pezesha/users/failed',
    component: AggUsersLogs,
    name: 'pezesha-users'
},

{
    path: '/credits',
    component: CreditsDashboard,
    name: 'credits-dashboard'
},
{
    path: '/credits/transactions',
    component: CreditsTransactions,
    name: 'credits-transactions'
},
{
    path: '/credits/farmers',
    component: CreditsFarmers,
    name: 'farmer-credits'
},
{
    path: '/credits/bought',
    component: CreditsBought,
    name: 'credits-bought'
},
{
    path: '/credits/usage',
    component: CreditsUsage,
    name: 'credits-usage'
},
{
    path: '/*',
    component: Login,
    name: 'unknown'
}
];

const router = new Router({
    routes,
    // mode: "history",
    base: process.env['VUE_APP_BASE']
});

export const bus = new Vue();

//custom directive for click outside
Vue.directive('click-outside', {
    priority: 700,
    bind() {
        let self = this
        this.event = function (event) {
            // console.log('emitting event')
            self.vm.$emit(self.expression, event)
        }
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },

    unbind() {
        // console.log('unbind')
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.event)
    },
    stopProp(event) {
        event.stopPropagation()
    }
})

window.axion.interceptors.response.use((response) => { // intercept the global error
    return response
}, function (error) {
    if (error.response.status === 401) { // if the error is 401
        router.push('/');
        return
    }

    return Promise.reject(error)
})
router.beforeEach((to, from, next) => {
    if (to.name !== 'auth' && !sessionStorage.getItem('token')) {
        next({ name: 'auth' });
    } else if (to.name.includes('credits') && !sessionStorage.getItem('admin')) {
        next({ name: 'auth' });
    } else {
        next();
    }
});
new Vue({
    router,
    render: h => h(App),

}).$mount('#app')

