<template>
  <div class="drop">
    <button @click.stop="export_ = !export_" class="light">
      <download-icon />export
    </button>

    <div v-if="export_" class="menu right" v-click-outside="closeEvent">
      <a @click="downloadExcel()">Download excel</a>


      <a @click="downloadPrint()">Print</a>
    </div>
    <loader :isLoading="loading" />
  </div>
  
</template>

<script>

import { DownloadIcon } from "vue-feather-icons";
import axios from "axios";
import Loader from "../components/Loader";
import Tables from "../mixins/Tables";

export default {
  name: "ExportOptions",
  components: { DownloadIcon, Loader },
  user: null,
  mixins: [Tables],
  props: {
    parameters: {
      type: Object,
      default: () => {
        return {
          url: "",
          partner: "",
          dates: {
            start: new Date(2020, 0, 1),
            end: new Date(),
          },
          
        };
      },
    },
    page:String,

    url: String,
  },
  data() {
    return {
      export_: false,
      records: Object,
      loading:false,
    };
  },
  methods: {
    async downloadExcel() {
      this.loading = true;
      const params = {...this.parameters,
        page:  "download",
        print: false
      }
      
      this.callAPI(params);

    },
    async callAPI(params) {
      axios
        .get(this.url, {
          headers: { Authorization: "Bearer " + this.user },
          params:params,
        })
        .then((response) => {

          this.loading = false;

          this.records = new Blob([response.data]);


          if(params["print"]==='print'){
            // for printing
            let doc = document.createElement('form');

            doc.innerHTML = response.data 


            doc.setAttribute('id','printable');

            document.body.appendChild(doc);
            this.printdoc();
            document.body.removeChild(doc);

          } 
          else{
            // for file download
            let fileURL = window.URL.createObjectURL(this.records);

            let fileLink = document.createElement('a');


            fileLink.href = fileURL;

            fileLink.setAttribute('download', this.page+'.csv');

            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
          }
        })
        .catch((error) => {

          console.log(error);
          this.loading = false;
        });
    },
    closeEvent: function () {
      this.hideDropDown();
    },

    async downloadPrint() {
      this.loading = true;
      const params = {...this.parameters,
        page:  "download",
        print: "print"
      }
      await this.callAPI(params);

    },

    hideDropDown() {
      this.export_ = false;
    },
  },
  events: {
    closeEvent: function () {
      this.hideDropDown();
    },
  },
  created() {
    this.checkUser(this);
    this.admin = sessionStorage.getItem("admin");
    this.records = [];
  },
};
</script>