<template>
    <b-container fluid>
        <b-card class="float-right">
            <form @submit="login">
            <b-card-header>
                <b class="text-primary">Ezy</b><b class="text-success">Credits</b>
            </b-card-header>
            <b-card-body>
                <b-form-group
                label="Username"
                label-for="username"
                description="Enter your username"
                >
                <b-form-input
                v-model="username"
                placeholder="Enter your Username"
                ></b-form-input>

                </b-form-group>
                <b-form-group
                label="Password"
                label-for="password"
                description="Enter your password"
                >
                <b-form-input
                v-model="password"
                type="password"
                placeholder="Enter your password"
                ></b-form-input>

                </b-form-group>
            </b-card-body>   
            <b-card-footer>
                <b-button v-if="!loading" variant="primary" type="submit">Login</b-button>
                <b-spinner  v-if="loading"  class="text-center" variant="success"></b-spinner>
            </b-card-footer>         
        </form>
        </b-card>
    </b-container>
</template>
<script>
import axios from "axios";
export default {
    name: "UserAuth",
    data(){
        return {
            username:'',
            password:'',
            loading:'',
            uri: process.env.VUE_APP_BACKEND_URL,
        }
    },
    methods:{
        login(){
        let url = this.uri + "api/v2/credits/auth/";
        this.loading =true

        axios
          .post(url, null, {
            auth: { 
                username:this.username,
                password:this.password
            },
          })
          .then( (response)=> {

            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("user", response.data.user.name);
            sessionStorage.setItem("admin", response.data.user.admin?response.data.user.admin:'');
            this.loading = false;
            this.$bvToast.toast(`Successful`, {
                title: 'Successful Login',
                variant:'success',
                solid: true
            })
            this.$router.push("/loans/dashboard");
          })
          .catch((error)=> {
            
            this.loading = false;
            this.$bvToast.toast(`Invalid username or password`, {
                title: 'Unsuccessful Login',
                variant:'danger',
                solid: true
            })
            console.log(error);
          });

    }
}
}
</script>
