<template>
  <div>
    <b-modal
      ref="check-user-exists"
      body-class="mymodal-body"
      static-backdrop
      no-fade
      no-close-on-esc
      no-close-on-backdrop
      id="check-user-exists"
      title="Register User for a loan"
      hide-footer
    >
      <b-container>
        <label class="text-secondary control-label">Check for User</label>
        <hr
      /></b-container>
      <b-container class="text-center" v-if="loading">
        <div class="p-3">
          Loading...<b-spinner variant="secondary"></b-spinner>
        </div>
      </b-container>
      <b-container v-if="!loading">
        <form @submit="checkUserExists">
          <b-form-group description="Enter user's phone number: 2567xxxxxxxx">
            <label class="control-label">Phone</label>
            <input
              required
              class="form-control"
              v-model="phone"
              placeholder="2567xxxxxxxx"
              type="number"
              min="90000000000"
              max="1000000000000"
            />
          </b-form-group>
          <b-button variant="outline-primary" class="float-right" type="submit"
            >Check</b-button
          >
        </form>

        <form
          v-if="farmer.account_id"
          @submit="updateUser"
          title="Check for User"
        >
          <label class="control-label">Farmer Details</label>

          <hr />
          <b-row>
            <b-col>
              <b-form-group description="User's phone number">
                <label class="control-label">Phone</label>
                <input
                  required
                  disabled
                  class="form-control"
                  v-model="farmer.farmer_phone_number"
                  type="number"
                  min="90000000000"
                  max="1000000000000"
                />
              </b-form-group>
              <b-form-group description="Enter Full Name on ID">
                <label class="control-label">Farmer Name</label>
                <input
                  required
                  class="form-control"
                  v-model="farmer.farmer_name"
                  minlength="6"
                  maxlength="60"
                />
              </b-form-group>
              <b-form-group description="Enter Date of Birth">
                <label class="control-label">Date of Birth</label>
                <!-- <b-form-datepicker :editable="true" v-model="farmer.farmer_dob"></b-form-datepicker> -->
                <v-date-picker
                  required
                  format="YYYY-DD-MMM"
                  v-model="farmer.farmer_dob"
                  :input-props="{
                    class: 'form-control',
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group description="Select User's gender">
                <label class="control-label">Gender</label>
                <select
                  required
                  class="form-control"
                  v-model="farmer.farmer_gender"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </b-form-group>
              <b-form-group description="Select User's district">
                <label class="control-label">District</label>
                <select
                  required
                  @change="getCoordinates"
                  class="form-control"
                  v-model="farmer.farmer_district"
                >
                  <option
                    v-for="(item, index) in districts"
                    :key="index"
                    :value="index"
                  >
                    {{ index }}
                  </option>
                </select>
              </b-form-group>
              <b-form-group description="Enter User's village">
                <label class="control-label">Village</label>
                <input
                  required
                  class="form-control"
                  v-model="farmer.farmer_village"
                  minlength="3"
                  maxlength="50"
                />
              </b-form-group>
              <b-row class="text-center" v-if="checkingRegistration">
                
                Checking Registration...<b-spinner variant="secondary"></b-spinner>
                
            </b-row>
              <b-button v-if="!alreadyRegistered" variant="primary" class="mb-3 float-right" type="submit"
                >Proceed</b-button
              >
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-modal>
    <create-new-user> </create-new-user>
  </div>
</template>
<script>
import Tables from "@/mixins/Tables";
import axios from "axios";
import CreateNewUser from "./CreateUser.vue";
import { bus } from "@/main";
export default {
  name: "CheckUser",
  mixins: [Tables],
  props: {
    districts: {
      default: () => {
        return {};
      },
    },
  },
  components: {
    CreateNewUser,
  },
  data() {
    return {
      phone: "",
      uri: process.env["VUE_APP_PROFILE_URL"],
      credits_uri: process.env["VUE_APP_BACKEND_URL"],
      farmer: {},
      loading: false,
      profile: {},
      yearRange: "",
      coordinates: {},
      alreadyRegistered: false,
      checkingRegistration: false,
    };
  },
  created() {
    bus.$on("createUser", () => {
      this.checkUser(this);
      this.resetUser();

      this.$bvModal.show("check-user-exists");
      // // console.log(payload);
    });
  },
  methods: {
    resetUser() {
      this.farmer = {};
      this.profile = {};
      this.coordinates = {};
      this.phone = "";
      this.alreadyRegistered = true;
    },
    getValueChain() {
      return this.farmer.preferred_value_chain
        ? this.farmer.preferred_value_chain.toString()
        : "";
    },
    updateUser() {
      this.loading = true;
      this.farmer = {
        ...this.farmer,
        preferred_value_chain: this.getValueChain(),
        farmer_dob: this.formatDate(this.farmer.farmer_dob),
      };
      axios
        .post(this.uri + "/api/v1/auth/update_farmer", this.farmer, {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then(() => {
          this.loading = false;
          this.registerUser();
          //   this.user = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$bvToast.toast("failed to update profile", {
            variant: "danger",
            title: "Error occured",
          });
          console.log(err);
        });
    },
    checkUserExists() {
      this.loading = true;
      axios
        .post(
          this.uri + "/api/v1/auth/check_farmer",
          {
            phone: this.phone,
          },
          {
            headers: { Authorization: "Bearer " + this.user },
          }
        )
        .then((res) => {
          this.loading = false;
          this.farmer = res.data;
          this.farmer.farmer_dob = new Date(
            this.fixDate(this.farmer.farmer_dob)
          );
          if (this.farmer.farmer_district !== "NA") {

            this.getCoordinates();
          }
          if (this.farmer.farmer_name !== "NA") {
            this.checkAlreadyRegistered();
          }else{
            this.alreadyRegistered=false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$bvToast.toast("Error occured, check the phone number you entered", {
                variant: "success",
                title: "FAILED!!",
            });
            console.log(err);
        });
    },
    checkAlreadyRegistered() {
      this.checkingRegistration = true;
      axios
        .get(
          this.credits_uri + "/api/v3/loans/pezesha/user/",
          {
            headers: { Authorization: "Bearer " + this.user },
            params: {
              user_id: this.farmer.farmer_id,
            },
          },
        )
        .then((res) => {
          this.checkingRegistration = false;
          if (res.data.length>0) {
            this.alreadyRegistered = true;
            this.$bvToast.toast("User already Registered", {
                variant: "success",
                title: "REGISTERED!!",
            });
          } else {
            this.alreadyRegistered = false;
          }
        })
        .catch((err) => {
          this.checkingRegistration = false;
          this.alreadyRegistered = false;
          console.log(err);
        });
    },
    getCoordinates() {
      axios
        .get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: `${this.farmer.farmer_district} uganda`,
            city: this.farmer.farmer_district,
            country: "Uganda",
            format: "json",
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            // Retrieve latitude and longitude from response
            const result = response.data[0];
            this.coordinates = {
              lat: result.lat,
              lng: result.lon,
            };
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerUser() {
      this.profile = {
        amount: 0,
        farmer_name: this.farmer.farmer_name,
        farmer_dob: this.farmer.farmer_dob,
        farmer_id: this.farmer.farmer_id,
        user_id: this.farmer.farmer_id,
        contact: this.farmer.farmer_phone_number,
        district: this.farmer.farmer_district,
        village: this.farmer.farmer_village,
        user_type: "merchant",
        vaId: this.farmer.vaId,
        gender: this.farmer.farmer_gender,
        date: this.formatDate(new Date()),
        time: this.formatDateTime(new Date()),
        geo: this.coordinates,
        reg_date: this.formatDate(new Date()),
        device_name: "",
        android_version: "",
        id_expiry: "",
        financial_card_number: "",
        insurance: "",
        id_photo_url: "",
        other_income_amount: 0,
        other_income_source: "",
        other_loan_amount: 0,
        other_loan_source: "",
        number_of_dependants: 0,
        OtherLoans: "",
        residential_address: "",
        number_of_school_going: 0,
        spouse_name: "",
        state: "active",
        status: "pending",
      };
      this.$bvModal.hide("check-user-exists");
      bus.$emit("addUser", this.profile);
    },
  },
};
</script>
