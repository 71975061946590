<template>
  <div>
    <b-modal
      ref="register-user"
      body-class="modal-body"
      static-backdrop
      no-fade
      no-close-on-esc
      no-close-on-backdrop
      id="register-user"
      size="xl"
      scrollable
      hide-footer
      title="Register User for a loan"
    >
      <b-container class="text-center" v-if="loading">
        <div class="p-3">
          Loading...<b-spinner variant="secondary"></b-spinner>
        </div>
      </b-container>
      <b-container v-if="!loading">
        <form @submit="registerUser">
          <b-row>
            <b-col class="border-right">
              <b-form-group description="Enter User's email address">
                <label class="control-label">Email</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.email"
                  minlength="6"
                  maxlength="70"
                />
              </b-form-group>
              <b-form-group description="Enter User's NIN">
                <label class="control-label"
                  >National ID Number(<b>NIN</b>)</label
                >
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.nin"
                  minlength="14"
                  maxlength="14"
                />
              </b-form-group>
              <b-form-group>
                <label class="control-label">National ID Image</label>
                <b-form-file
                  v-model="file1"			
                  :state="Boolean(file1)"
                  placeholder="Choose a Image or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".jpg, .png, .gif"
                ></b-form-file>
                <div class="mt-3">
                  Selected file: {{ file1 ? file1.name : "" }}
                </div>
              </b-form-group>
              <b-form-group
                description="Enter user's highest level of education"
              >
                <label class="control-label">Highest level of education</label>
                <select class="form-control" v-model="loanProfile.education">
                  <option value="Primary">Primary</option>
                  <option value="Secondary">Secondary</option>
                  <option value="University">University</option>
                </select>
              </b-form-group>
              <b-form-group description="Enter user's marital status ">
                <label class="control-label">Marital Status</label>
                <select
                  class="form-control"
                  v-model="loanProfile.marital_status"
                >
                  <option
                    v-for="(item, index) in maritalStatus"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group description="Enter User's Financial Card Number">
                <label class="control-label">Financial Card Number</label>
                <input
                  class="form-control"
                  v-model="loanProfile.financial_card_number"
                />
              </b-form-group>
              <b-form-group description="Enter User's business name">
                <label class="control-label">Business Name</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.business_name"
                  minlength="3"
                  maxlength="80"
                />
              </b-form-group>
              <b-form-group description="Enter User's business address">
                <label class="control-label">Business Address</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.business_address"
                  minlength="6"
                  maxlength="120"
                />
              </b-form-group>
              <b-form-group description="Enter User's business start date">
                <label class="control-label">Business Start Date</label>
                <v-date-picker
                  required
                  format="YYYY-DD-MMM"
                  v-model="loanProfile.business_start_date"
                  :input-props="{
                    class: 'form-control',
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col class="border-left">
              <b-form-group description="Enter User's Next of kin">
                <label class="control-label">Next of Kin(Full Name)</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.next_of_kin_name"
                  minlength="3"
                  maxlength="40"
                />
              </b-form-group>
              <b-form-group
                description="Enter User's Next of kin's phone number: 2567xxxxxxxx"
              >
                <label class="control-label">Next of Kin Contact</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.next_of_kin_contact"
                  type="number"
									placeholder="2567xxxxxxxx"
                  min="90000000000"
                  max="1000000000000"
                />
              </b-form-group>
              <b-form-group
                description="Enter User's Next of kin National ID Number"
              >
                <label class="control-label">Next of Kin NIN</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.next_of_kin_nin"
                  minlength="14"
                  maxlength="14"
                />
              </b-form-group>
              <b-form-group
                description="How's the user related to his/her Next of kin? Brother,Spouse, etc.."
              >
                <label class="control-label">How are you related?</label>
                <input
                  required
                  class="form-control"
                  v-model="loanProfile.next_of_kin_relation"
                  minlength="3"
                  maxlength="30"
                />
              </b-form-group>
              <b-container class="text-center" v-if="uploading">
                <div class="p-3">
                  Uploading Image...<b-spinner variant="primary"></b-spinner>
                </div>
              </b-container>
              <b-button
                v-if="!uploading"
                class="float-right"
                variant="success"
                type="submit"
              >
                Register {{ loanProfile.farmer_name }}
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-container>
      <div
        class="submodal text-center"
        v-show="viewIdphoto == true"
        key="829029899"
        style="width: 300pt"
      >
        <div class="text-center d-block">
          <img class="img-fluid" :src="loanProfile.id_photo_url" />
        </div>
        <button class="m-auto" @click="viewIdphoto = false">Close</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Tables from "@/mixins/Tables";
import axios from "axios";
import { bus } from "@/main";
export default {
  name: "CreateUser",
  mixins: [Tables],
  data() {
    return {
      url: process.env["VUE_APP_BACKEND_URL"],
      loading: false,
      loanProfile: {},
      file1: null,
      uploading: false,
      viewIdphoto: false,
      maritalStatus: ["Single", "Married", "Divorced", "Widow", "Widower"],
    };
  },
  methods: {
    registerUser() {
			this.loading=true
			this.loanProfile={
				...this.loanProfile,
				residential_address:this.loanProfile.business_address
			}
      axios
        .post(this.url + "/api/v3/loans/pezesha/user/", this.loanProfile, {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then(() => {
          this.loading = false;
					this.$bvToast.toast("Registered User", {
            variant: "success",
            title: "REGISTERED SUCCESSFULLY!!",
          });
        })
        .catch((err) => {
          this.loading = false;
					this.$bvToast.toast("failed to register user", {
            variant: "danger",
            title: "Error occured",
          });
          console.log(err);
        });
    },
    onImageSelect() {
      if (this.file1) {
        this.checkFileSize();
      }
    },

    checkFileSize() {
      if (this.file1.size > 1058576) {
        this.file1 = null;
        return this.$bvToast.toast("File should be less than 1mb", {
          variant: "danger",
          title: "Error occured",
        });
      } else {
        return this.uploadImage();
      }
    },
    uploadImage() {
      this.uploading = true;
      let image = new FormData();
      image.append("image", this.file1);
      axios
        .post(this.url + "/api/v2/images", image, {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then((res) => {
          this.loanProfile.id_photo_url = res.data.file;
					this.loanProfile.nin_photo_url = res.data.file;
          this.viewIdphoto = true;
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
          this.loanProfile.id_photo_url = null;
          this.viewIdphoto = false;
          console.log(err);
        });
    },
  },
  created() {
    bus.$on("addUser", (user) => {
      this.checkUser(this);
      this.loanProfile = user;
			this.viewIdphoto=false;
			this.file1=null;
      this.$bvModal.show("register-user");
    });
  },
  watch: {
    file1: {
      handler() {
        this.onImageSelect();
      },
    },
  },
};
</script>
