<template>
  <div class="px-2">
    <div class="row mx-0 my-2 filter">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Cleared Loans ({{activeloans.length}})</h1>
        </div>
      </div>
      <div class="col-auto">
        <loan-filter @updateFilter="updatefilter" :filters="filters" />
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0">
        <div class="card-header p-2">
          <div class="row">
            <season-filter @updateFilter="updatefilter" :filters="seasonfilter"/>
            <partner-filter @updateFilter="updatefilter" :filters="coopfilter" />
            <div class="col-auto px-0">
              <input class="search form-control py-0 m-0" placeholder="search" v-model="searchQuery" @keyup="onSearch"/>
            </div>
            <div class="col-auto">
              <export-options page="LoansCleared" :parameters="parameters" :url="pageUrl" :loading="loading"/>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-holder" ref="holder" :style="holderstyles">
            <table class="w-100 table-stripes table h" ref="table">
              <thead>
                <th></th>

                <th>Name</th>

                <th>Principal</th>
                <th width="1pt">Fee</th>
                <th class="text-right">Total loan</th>
                <th class="text-right">Total Balance</th>
                <th class="text-right" width="1px"></th>
                <th class>Due date</th>
                <th class>Contact</th>
                <th width="1px"></th>
              </thead>
              <tbody>
                <tr v-for="(loan,index) in activeloans" :key="index">
                  <td width="1px" class>{{index + 1}}</td>


                  <td class="txt-primary">{{(loan.name.length>13?loan.name.substring(0, 12)+'...':loan.name) | capitalize}}</td>
                  <td class="text-muted">{{loan.amount | addCommas}}</td>
                  <td width="1px" class>
                    <span class="bg-accent p-1 text-white rounded">{{loan.interest | addCommas}}</span>
                  </td>
                  <td class="text-right">
                    <b>{{loan.total | addCommas}}</b>
                  </td>

                  <td class="text-right">
                    <b class="txt-primary">{{loan.balance | addCommas}}</b>
                  </td>
                  <td class="text-right">
                    <span class="dot"></span>
                  </td>
                  <td class>
                    <span class="txt-primary">{{loan.due_date | moment}}</span>
                  </td>
                  <td class>
                    <b class>{{loan.contact | contact}}</b>
                  </td>

                  <td class="text-muted">
                    <button
                      class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                      @click="showLoanDetails(loan.farmer_id, $data)"
                    >View details</button>
                  </td>
                </tr>
                 <tr>
                  <td colspan="9" style="background:white;">
                <infinite-loading :identifier="infiniteId" @infinite="getActiveLoans"></infinite-loading>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-auto">
              <span class="txt-primary">CLEARED LOANS:</span>
              <b>{{activeloans.length}}</b>
            </div>

            <div class="col-auto">
              <span class="txt-primary">TOTAL LOAN AMOUNT:</span>
              <b>{{totalLoanAmount}}</b>
            </div>

            <div class="col-auto">
              <span class="text-danger">TOTAL LOAN BALANCE:</span>
              <b>{{totalLoanBalance}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  <loader :isLoading="loading" />
    <!--modal-->
  </div>
</template>

<script>
import Tables from "../mixins/Tables";
import { bus } from "../main.js";


import LoanFilter from "../components/LoanFilter";
import SeasonFilter from "../components/SeasonFilter";
import PartnerFilter  from "../components/PartnerFilter";
import ExportOptions from "../components/ExportOptions";
import Loader from "../components/Loader";
import axios from "axios";

export default {
  name: "LoansCleared",
  components: { LoanFilter, SeasonFilter,  PartnerFilter, ExportOptions, Loader },
  props: {
    maID: String,
  },
  mixins: [Tables],
  data() {
    return {
      activeloans: [],
      totalLoanAmount: 0,
      loading: false,
      user: null,
      totalLoanBalance: 0,
      uri:process.env['VUE_APP_BACKEND_URL'],
      holderstyles: {
        height: "0px",
      },
      filters:{
      

        district: "All districts",
        partner: "",
        dates: {
        start: new Date(),
        end:  new Date(),
        
      }},
      coopfilter:{
        farmerType:'',
        cooperative:''
      },
      page: 1,
      searchQuery: '',
      infiniteId: +new Date(),
      loanSelected: [],
      seasonfilter:{
        season:'',
        page:'All Loans Cleared'
      },
      pageUrl: process.env['VUE_APP_BACKEND_URL']+"api/v2/loans/paid",
      parameters:{},
      export_: false,
    };
  },
  mounted: function () {
    this.checkUser(this);

    this.$nextTick(function () {
      this.setTableHeight("holder", this.holderstyles);
    });
  },

  methods: {
    showLoanDetails(index, dataObj) {
      //alert(JSON.stringify(this.activeloans[index]))
      this.getLoan(index, dataObj);
    },
    onSearch(){
      this.page = 1;
      this.parameters = {
        page: this.page,
        name: this.searchQuery,
        district: this.filters.district==='All districts'?'':this.filters.district,
        start: this.formatDate(this.filters.dates.start),
        end:this.formatDate(this.filters.dates.end),
        maID:this.coopfilter.cooperative,
        farmerType:this.coopfilter.farmerType,
        season:this.seasonfilter.season,
        partner: this.filters.partner==='All Partners'?'':this.filters.partner,
      }
      this.activeloans = [];
      this.infiniteId += 1;

    },

    getLoan(id, dataObj){
      dataObj.loading  = true;
    


      axios
        .get(dataObj.uri+"api/v2/loans/"+id+"/recent/", {
          headers:{'Authorization': 'Bearer ' + dataObj.user},
        })
        .then(function (response) {


          dataObj.loading = false;
          dataObj.loanSelected=response.data.loan_request[0];
          // dataObj.loanSelected.tab = 'application';
          // // console.log(dataObj.loanSelected);
          bus.$emit("showdetails", dataObj.loanSelected)
          
        })
        .catch(function (error) {
          console.log("Response=" + JSON.stringify(error));
          dataObj.loading = false;
          return false;
        });
    },

    getActiveLoans($state) {
      let dataObj = this;
      // dataObj.loading = true;
      dataObj.parameters = {
        page: dataObj.page,
        name: dataObj.searchQuery,
        district: dataObj.filters.district==='All districts'?'':dataObj.filters.district,
        start: dataObj.formatDate(dataObj.filters.dates.start),
        end:dataObj.formatDate(dataObj.filters.dates.end),
        maID:dataObj.coopfilter.cooperative,
        farmerType:dataObj.coopfilter.farmerType,
        season:dataObj.seasonfilter.season,
        partner: dataObj.filters.partner==='All Partners'?'':dataObj.filters.partner,
      }
      axios
        .get(dataObj.pageUrl, {
          headers:{'Authorization': 'Bearer ' + dataObj.user},
          params: dataObj.parameters,
        })
        .then(function (response) {

          dataObj.page++;
          if(response.data.loan_requests.length>=25){
            dataObj.activeloans = dataObj.activeloans.concat(response.data.loan_requests);
            $state.loaded();
          }
          else if(response.data.loan_requests.length>0) {
            dataObj.activeloans = dataObj.activeloans.concat(response.data.loan_requests);
            // dataObj.loading = false;
            $state.loaded();
            throw false;
          }
          else{
            throw false;
          }
        })
        .catch(function (error) {
          console.log(error);
          // dataObj.loading = false;
          $state.complete();
        });


    },
  },
  watch:{
    loanSelected(){
      // console.log('change', x);
    },

    filters:{
      handler(){
        this.onSearch();
      },
      deep:true
    },
    coopfilter:{
      handler(){
        this.onSearch();
      },
      deep:true
    },
    seasonfilter:{
      handler(){
        this.onSearch();
      },
      deep:true
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dot {
  height: 10pt;
  width: 10pt;
  border-radius: 10pt;
  background-color: #e8e8e8;
  display: inline-block;
}
</style>
