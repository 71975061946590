import moment from 'moment'
import printJS from 'print-js';

import utf8 from 'utf8'

export default {
    filters: {
        addCommas(val) {
            if (val == undefined) return 0;
            return parseFloat(val).toLocaleString()
        },
        moment(date) {
            return moment(date).format('DD MMM YYYY');
        },
        dateandtime(date) {
            return moment(date).format('DD MMM YYYY HH:MM:SS');
        },
        time(time){
            return time?time.substring(0,8):'-';
        },
        age(date) {
            return moment().diff(date, 'years');
        },
        months(date) {
            return moment(date).diff(moment().format('YYYY-MM-DD'), 'months');
        },
        datetime(date) {
            return moment(date).format('LLLL');
        },
        contact(contact) {
            return contact ? contact : '-';
        },
        space(word) {
            return word ? (word.replace(/_/g, ' ')) : '';
        },
        decimals(amount) {
            return amount && amount !== '-' ? (Number(amount).toFixed(4)) : '0';
        },
        capitalize(word) {
            return (word.toLowerCase()).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        }


    },
    data() {
        return {
            name: ''
        }
    },
    methods: {
        checkUser(dataObj) {
            sessionStorage.getItem('token') ? (dataObj.user = sessionStorage.getItem('token')) : dataObj.$router.push('/');

        },
        momentdate() {
            return moment();
        },
        formatNum(val) {
            if (val == undefined) return 0;
            return parseFloat(val).toLocaleString()
        },
        convert(img) {
            return "https://drive.google.com/uc?export=view&id=" + img.split('id=')[1];
        },
        printdoc() {
            printJS({
                printable:'printable', 
                type:'html',
                style: '@page { size: Letter landscape; }'
            })
        },
        updatefilter(payload){
            this.filters = {...this.filters, ...payload}
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        formatDateTime(date) {
            return moment(date).format('YYYY-MM-DD HH:MM:SS');
        },
        fixDate(date){
            if (date.includes('-')){
                return this.formatDate(date)
            }else if(date.includes('/')){
                return this.formatDate(moment(date, "DD/MM/YYYY"));
            }else{
                return this.formatDate(date)
            }   
        },
        utf8(word) {
            return utf8.encode(word);
        },
        setTableHeight(holder, styles) {
            var offset = this.$refs[holder].getBoundingClientRect().y,
                windowHeight = window.innerHeight,
                h = windowHeight - offset - 60;
            //alert(offset)
            styles.height = h + 'px';
        },
    }
}
