<template>
  <div>
    <b-modal
      ref="details-modal"
      body-class="mymodal-body"
      static-backdrop
      no-fade
      no-close-on-esc
      no-close-on-backdrop
      id="details-modal"
      size="xl"
      scrollable
      hide-header
      hide-footer
      title
    >
      <div class="row m-0">
        <div class="col-3 left p-0">
          <div class="py-3 px-4">
            <h4>{{loan.farmer_name}}</h4>
            <p>
              Loan ID: #{{loan.id}}
              <chevron-down-icon />
            </p>
          </div>
          <div class="tabs" role="tablist">
            <a
              v-if="loan.status==='approved' || loan.status==='closed'"
              class="tab-link"
              :class="{active: tab=='details'}"
              @click="switchtab('details')"
            >Loan details</a>
            <a
              v-if="loan.status==='approved' || loan.status==='closed'"
              class="tab-link"
              :class="{active: tab=='usage'}"
              @click="switchtab('usage')"
            >Loan usage</a>
            <a
              class="tab-link"
              :class="{active: tab=='application'}"
              @click="switchtab('application')"
            >Loan application</a>
            <a
              v-if="admin"
              class="tab-link"
              :class="{active: tab=='credit-score'}"
              @click="switchtab('credit-score')"
            >Credit score</a>
          </div>
        </div>
        <div class="col p-0 position-relative">
          <div
            class="py-2 bg-white border-bottom"
            style="box-shadow:3px 3px 5pt rgba(0,0,0,.06); position:relative; z-index:50"
          >
            <div class="row m-0">
              <div class="col-12 col-md">
                <h4 class="m-0 text-capitalize m-title">{{tab}}</h4>
              </div>
              <div class="col-12 col-md-auto">
                <button class="mr-2 light btn-menu">
                  <printer-icon />Print
                </button>
                <button @click="closeDetails" class="light btn-menu">
                  <x-icon></x-icon>Close
                </button>
              </div>
            </div>
          </div>
          <div class="loans-content position-relative bg-light">
            <div
              class="tab-view"
              v-if="loan.status==='approved' || loan.status==='closed'"
              v-show="tab=='details'"
              key="details"
            >
              <div class="p-3 border">
                <div class="row m-0">
                  <div class="col-lg-6 col-12">
                    <div class="row">
                      <div class="col-lg-6 col-12 pl-0">
                        <div class="label">Principal</div>
                        <div class="val text-primary-dark">{{loan.amount | addCommas}}</div>

                        <div class="label">Total loan</div>
                        <div v-show="loan.total && !loading" class="val">
                          {{loan.total | addCommas}}
                          <span
                            class="badge bage-success"
                          >{{loan.interest}}%</span>
                        </div>

                        <div class="label">Balance</div>
                        <div v-show="loan.balance && !loading" class="val">{{loan.balance  | addCommas}}</div>
                        <div v-show="loading" class="val">loading...</div>
                      </div>

                      <div class="col-lg-6 col-12">
                        <div
                          v-if="loan.status==='approved' || loan.status==='closed'"
                          class="label"
                        >
                          <check-circle-icon />Date approved
                        </div>
                        <div
                          v-if="loan.status==='approved' || loan.status==='closed'"
                          class="val"
                        >{{loan.date_approved.split('T')[0] | moment}}</div>

                        <div class="label">
                          <calendar-icon />Due date
                        </div>
                        <div class="val">{{ loan.due_date.split('T')[0] | moment }}</div>

                        <div class="label">
                          <clock-icon />Payment period
                        </div>
                        <div class="val">
                          {{loan.due_date.split('T')[0] | months }}
                          <small>month(s) from now</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0 border-left">
                    <table class="tb table table-light">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th class="text-right">Amount (UGX)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in loan.items" :key="index">
                          <td>{{item}}</td>
                          <td>
                            {{loan.quantities[index]}}
                            <small></small>
                          </td>
                          <td class="text-right">{{loan.quantities[index]*loan.unit_cost[index]| addCommas}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="loan.status==='approved' || loan.status==='closed'" class="row m-0">
                <div class="col">
                  <h4 class="stitle mb-0 pt-2">Loan payments ({{payments?payments.length:0}})</h4>
                </div>
                <div class="col-auto pb-2 pt-1">
                  <button class="btn-menu" @click="newPayment()">
                    <plus-icon />Add payment
                  </button>
                </div>
              </div>

              <div
                v-if="(loan.status==='approved' || loan.status==='closed') && payments"
                class="px-3"
              >
                <table class="tb table table-secondary">
                  <thead>
                    <tr>
                      <th>payment date</th>
                      <th>Time</th>
                      <th>Amount</th>
                      <!-- <th class="text-center">Quantity</th> -->
                      <!-- <th class="text-right">unit price</th> -->
                      <!-- <th class="text-right">Amount</th> -->
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in payments" :key="index">
                      <td>{{item.date | moment}}</td>
                      <td>{{item.time }}</td>
                      <td>{{item.amount | addCommas}}</td>
                      <!-- <td>{{item.balance}}</td> -->
                      <td>
                        <a @click="editPayment(index)" class="btn">Edit</a>
                      </td>
                    </tr>
                    <tr v-if="!(payments.length>0) && !loading">
                      <td colspan="2">No payments...</td>
                      <!-- <td></td> -->
                      <td></td>
                      <!-- <td>{{item.balance}}</td> -->
                      <td>
                        <!-- <a @click="editPayment(index)" class="btn">Edit</a> -->
                      </td>
                    </tr>
                    <tr v-if="loading">
                      <td colspan="2">Loading...</td>
                      <!-- <td></td> -->
                      <td></td>
                      <!-- <td>{{item.balance}}</td> -->
                      <td>
                        <!-- <a @click="editPayment(index)" class="btn">Edit</a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-view" v-show="tab=='usage'" key="usage">
              <div class="container">
                <div class="p-3 row">
                <h4 class="stitle col-sm-12">Quantities used vs quantities approved</h4>
                <table class="table table-secondary col-sm-6">
                  <thead>
                    <tr>
                      <th class="br"></th>
                      <th colspan="2" class="text-center br">Approved</th>
                    </tr>

                    <tr>
                      <th class="br">ITEM</th>
                      <th class>QUANTITY</th>
                      <th class="br text-right">AMOUNT</th>

                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in loan.items" :key="index">
                          <td>{{item}}</td>
                          <td>
                            {{loan.quantities[index]}}
                            <small></small>
                          </td>
                          <td class="text-right">{{loan.quantities[index]*loan.unit_cost[index] |  addCommas}}</td>
                        </tr>
                  </tbody>
                </table>
                <table class="table table-secondary col-sm-6">
                  <thead>
                    <tr>
                      <th class="br"></th>
                      <th colspan="2" class="text-center br">Used</th>
                    </tr>

                    <tr>
                      <th class="br">ITEM(s)</th>
                      <th class>QUANTITY</th>
                      <th class="br text-right">AMOUNT</th>

                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in usage" :key="index">
                          <td>{{item.items}}</td>
                          <td>
                            {{item.used | addCommas}}
                            <small></small>
                          </td>
                          <td class="text-right">{{item.date[0] | moment}}</td>
                        </tr>
                  </tbody>
                </table>
                <h4 class="stitle mt-5 col-sm-12">Monitoring</h4>
                <div class="row m-0">
                  <div
                    class="col-lg-3 col-12 pl-0"
                    v-for="(item, index) in monitoringdata"
                    :key="index"
                  >
                    <div class="card-usage">
                      <p class="m-0">{{item.key}}</p>
                      <h5 class="text-secondary font-weight-normal">{{item.value}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              
            </div>

            <div class="tab-view bg-light p-0" v-show="tab=='application'" key="application">
              <div class="row m-0">
                <div class="col py-3">
                  <div class="card app-card mb-3">
                    <h4 class="stitle">Reason for loan</h4>
                    <table class="table table-light">
                      <thead>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th class="text-right">Amount (UGX)</th>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in loan.items" :key="index">
                          <td>{{item}}</td>
                          <td>
                            {{loan.quantities[index]}}
                            <small></small>
                          </td>
                          <td class="text-right">{{loan.quantities[index]*loan.unit_cost[index] | addCommas}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card app-card mb-3">
                    <h4 class="stitle">Bio info</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="label">Name</p>
                        <p class="val">{{loan.farmer_name}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Age, Gender</p>
                        <p class="val">{{loan.farmer_dob | age}}, {{loan.gender}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Phone</p>
                        <p class="val">{{loan.contact | contact}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Email</p>
                        <p class="val">{{loan.email}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Email</p>
                        <p class="val">{{loan.email}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Address</p>
                        <p class="val">{{loan.district}}, {{loan.village}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">ID card</p>
                        <p class="val">
                          {{loan.nin}}
                          <a @click="viewIdphoto=true" class="link-btn">View ID</a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card app-card mb-3">
                    <h4 class="stitle">Garden info</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="label">Total Size</p>
                        <p class="val">
                          {{loan.acreage | decimals}}
                          <span class="text-muted">(Owner)</span>
                        </p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Total size cultivated</p>
                        <p class="val">{{loan.acreage | decimals}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Main Crop</p>
                        <p class="val">{{loan.main_crop}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Other crops</p>
                        <p class="val">{{loan.other_crop}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Last yield</p>
                        <p class="val">{{loan.PreviousYield}}&nbsp;kgs</p>
                      </div>
                    </div>
                  </div>

                  <div class="card app-card mb-3">
                    <h4 class="stitle">Cooperative</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="val">{{coopData.name}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Period as a member</p>
                        <p class="val">{{loan.years_in_coop?loan.years_in_coop+' Years':'-'}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Cooperative active loans</p>
                        <p class="val">{{coopData.loans | addCommas}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Total loans cleared by cooperative</p>
                        <p class="val">{{coopData.cleared | addCommas}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Loan Eligibility</p>
                        <p class="val">
                          Yes
                          <check-circle-icon class="text-primary" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card app-card mb-3">
                    <h4 class="stitle">Additional info</h4>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <p class="label">Education level</p>
                        <p class="val">{{loan.education}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Dependants</p>
                        <p class="val">{{loan.number_of_dependants}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Main source of income</p>
                        <p class="val">{{loan.other_income_source}}</p>
                      </div>

                      <div class="col-12 col-lg-6">
                        <p class="label">Loan Eligibility</p>
                        <p class="val">
                          Yes
                          <check-circle-icon class="text-primary" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 pl-0">
                  <div class="loan-summary px-2 py-3">
                    <p v-if="admin" class="label">Credit score</p>
                    <p v-if="admin" class="val">{{credit_score?credit_score:fetching}}</p>

                    <p v-if="admin" class="label">Loan limit</p>
                    <p v-if="admin" class="val">{{ score_details.loan_limit?score_details.loan_limit:0 | addCommas}}</p>

                    <p class="label">Loan amount</p>
                    <p class="val text-primary">UGX {{loan.amount | addCommas}}</p>

                    <p class="label">Insurance</p>
                    <p class="val">{{loan.insurance}}</p>

                    <p v-if="admin" class="label">Eligibility</p>
                    <p v-if="admin" class="val">
                      <check-circle-icon class="text-primary" />Yes
                    </p>

                    <p v-if="loan.status==='approved' || loan.status==='closed'" class="val">
                      <check-circle-icon class="text-primary" />
                      Loan {{loan.status}}!
                    </p>
                    <button
                      v-if="!(loan.status==='approved' || loan.status==='closed' ) &&loan.status!=='rejected'"
                      class="btn-block mt-5 danger"
                      @click="editingapprove=true; approvesection='reject'"
                    >Reject</button>
                    <button
                      v-if="!(loan.status==='approved' || loan.status==='closed')"
                      class="btn-success btn-block"
                      @click="editingapprove=true; approvesection='approve';"
                    >Approve</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-view" v-show="tab=='credit-score'" key="credit-score">

              <div  
              style="padding:20pt"
              class="container">
                <tr >
                  <table class="tb table table-light">
                      <thead>
                        <tr>
                          <th colspan="2" style="color:#aaa; font-size:2em;">{{loan.farmer_name}}'s Credit Score</th>
                        </tr>
                        <tr>
                          <th>Item</th>
                          <th>Score</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in score_details" :key="index">
                          <td>{{index | space}}</td>
                          <td>
                            {{item | addCommas }}
                            <small></small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </tr>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="submodal" v-show="editingpayment == true && tab=='details'" key="8290298">
              <div classs="p-3">
                <h4 class="title mb-3">Add payment</h4>
                <label class="control-label">Payment date</label>
                <input class="form-control" v-model="paymentmodal.date" />

                <label class="control-label">Method</label>
                <select class="form-control" v-model="paymentmodal.method">
                  <option>Select</option>
                  <option>Cash</option>
                  <option>Produce</option>
                </select>

                <label class="control-label">Produce</label>
                <select class="form-control" v-model="paymentmodal.produce">
                  <option>Select</option>
                  <option>Cash</option>
                  <option>Produce</option>
                </select>

                <div class="row">
                  <div class="col">
                    <label class="control-label">Quantity</label>
                    <input class="form-control" v-model="paymentmodal.quantity" />
                  </div>
                  <div class="col pl-0">
                    <label class="control-label">Unit price</label>
                    <input class="form-control" v-model="paymentmodal.unitPrice" />
                  </div>
                </div>
                <label class="control-label">Total amount</label>
                <input class="form-control" v-model="paymentmodal.totalAmount" />

                <div class="py-2">
                  <button class="mr-2" @click="cancelPayment()">Cancel</button>
                  <button class>Save payment</button>
                </div>
              </div>
            </div>
          </transition>

          <div class="submodal" v-show="editingapprove == true && tab=='application'" key="8290298">
            <div>
              <!-- approve loan section  -->
              <div id="approve-loan" v-if="approvesection=='approve'">
                <h4 class="title mb-3">Approve loan</h4>
                <!-- <div v-for="(item, index) in loan.items" :key="index">
                  <label class="control-label">{{item}}</label>
                  <input class="form-control" v-model="loan.cost[index]" />
                </div>-->
                <div>
                  <label class="control-label">Amount Applied for</label>
                  <input type="number" class="form-control" v-model="loan.amount" />
                </div>
                <hr class="mb-2" />
                <label class="control-label">Interest rate</label>
                <select class="form-control" v-model="interestRate">
                  <option
                    v-for="(rate, index) in interestRates"
                    :key="index"
                    :value="rate.value"
                  >{{rate.name}} - {{rate.value}}%</option>
                </select>
                <br />
                <h4 class="stitle mb-1">Total Loan</h4>
                <h4 class="font-weight-bold">UGX {{loan.amount | addCommas}}</h4>
                <div class="py-2">
                  <button class="mr-2" @click="editingapprove=false;">Cancel</button>
                  <button @click="approveloan($data);" class="btn-success">Approve Loan</button>
                </div>
              </div>

              <!-- reject loan section -->
              <div class="loader" v-if="loading">
                <b-spinner label="Loading..."  variant="primary"></b-spinner>
                <p>Loading..</p>
              </div>

              <div id="reject-loan" v-if="approvesection=='reject'">
                <h4 class="title mb-3">Reject loan</h4>

                <label class="control-label">Reason</label>
                <textarea class="form-control" v-model="reason" rows="4"></textarea>

                <div class="py-2">
                  <button class="mr-2" @click="editingapprove=false">Cancel</button>
                  <button class="danger" @click="rejectloan($data);">Reject Loan</button>
                </div>
              </div>

              <div id="success-modal" v-if="approvesection=='success'">
                <h4 class="title mb-3">Successfully {{response}}</h4>

                <!-- <label class="control-label">Successful</label> -->

                <div class="py-2">
                  <button class="btn-success" @click="editingapprove=false">Ok</button>
                </div>
              </div>

              <div id="fail-modal" v-if="approvesection=='failed'">
                <h4 class="title mb-3">{{response}}</h4>

                <!-- <label class="control-label">Successful</label> -->

                <div class="py-2">
                  <button class="btn-success" @click="editingapprove=false">Ok</button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="submodal text-center"
            v-show="viewIdphoto == true && tab=='application'"
            key="829029899"
            style="width:300pt"
          >
            <div class="text-center d-block">
              <img
                class="img-fluid"
                :src="convert(loan.id_photo_url?loan.id_photo_url:loan.nin_photo_url?loan.nin_photo_url:'')"
              />
            </div>
            <button class="m-auto" @click="viewIdphoto =false">Close</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ChevronDownIcon,
  XIcon,
  PrinterIcon,
  CheckCircleIcon,
  ClockIcon,
  CalendarIcon,
  PlusIcon,
} from "vue-feather-icons";
import Tables from "../mixins/Tables";
import { bus } from "../main";

import axios from "axios";

export default {
  name: "LoanDetails",
  components: {
    ChevronDownIcon,
    XIcon,
    PrinterIcon,
    CheckCircleIcon,
    ClockIcon,
    CalendarIcon,
    PlusIcon,
  },
  mixins: [Tables],

  data() {
    return {
      loan: {},
      modalTitle: "Loan details",
      tab: "details",
      fetching: "loading...",
      uri: process.env["VUE_APP_BACKEND_URL"],
      user: null,
      loading: false,
      success: false,
      score_details: {},
      failed: false,
      credit_score: null,
      interestRate: null,
      editingpayment: false,
      editingapprove: false,
      approvesection: "",
      viewIdphoto: false,
      viewgardenmap: false,
      reason: null,
      response: null,
      usage:[],

      interestRates: [
        { name: "Standard", value: "3.0" },
        { name: "Old farmers", value: "2.5" },
      ], //get interest rates
      paymentmodal: {
        /*payment model */
        id: 0,
        date: "",
        method: "cash",
        produce: "",
        quantity: 0,
        units: "",
        unitPrice: 0,
        totalAmount: 0,
      },
      dataloading:{
        'loading...':0
      },
      coopData:{},
      admin: false,
      defaultCoop:{
        name: 'No Cooperative Data....',
        active: 0,
        cleared: 0
      },


      payments: [],
      old:[{date:'loading..', time:'', amount:0}],
      monitoringdata: [],
      usagedata: [],
    };
  },

  methods: {
    closeDetails() {
      this.$bvModal.hide("details-modal");
      bus.$emit("hidedetails");
    },

    switchtab(val) {
      //
      this.tab = val;
      //                alert(val)
    },

    newPayment() {
      this.editingpayment = true;
      this.resetPaymentModal();
    },

    editPayment(index) {
      this.editingpayment = true;
      this.resetPaymentModal();
      let pay = this.payments[index];

      /*set the payment modal to the clicked payment object*/
      this.paymentmodal.id = pay.id;
      this.paymentmodal.date = pay.date;
      this.paymentmodal.method = pay.method;
      this.paymentmodal.produce = pay.method;
      this.paymentmodal.quantity = pay.quantity;
      this.paymentmodal.unitPrice = pay.unitprice;
      this.paymentmodal.totalAmount = pay.amount;
      this.paymentmodal.units = "";
    },

    cancelPayment() {
      this.editingpayment = false;
      this.resetPaymentModal();
    },

    approveloan(dataObj) {
      dataObj.loading = true;
      // console.log("enters");

      axios
        .patch(
          dataObj.uri + "api/v2/loans/requests/approve/" + dataObj.loan.id,
          {
            amount_approved: Number(dataObj.loan.amount),
          },
          {
            headers: { Authorization: "Bearer " + dataObj.user },
          }
        )
        .then(function (response) {
          // // console.log("Response=" + JSON.stringify(response));

          dataObj.loading = false;
          dataObj.loan = response.data.loan;
          dataObj.loan.balance = dataObj.loan.total
          dataObj.approvesection = "success";
          dataObj.requestsuccess = true;
          dataObj.response = "approved!";
          // dataOb


        })
        .catch(function () {
          dataObj.requestsuccess = true;
          dataObj.approvesection = "failed";
          dataObj.approvesection = "fail";
          dataObj.response = "Failed to approve.";
          dataObj.loading = false;
          return false;
        })
        .catch(function () {

          dataObj.requestsuccess = true;
          dataObj.approvesection = "failed";
          dataObj.approvesection = "fail";
          dataObj.response = "Failed to approve.";
          dataObj.loading = false;
          return false;
        });
    },
    rejectloan(dataObj) {
      dataObj.loading = true;
      // console.log("enters");

      axios
        .patch(
          dataObj.uri + "api/v2/loans/requests/reject/" + dataObj.loan.id,
          {
            reason: dataObj.reason,
          },
          {
            headers: { Authorization: "Bearer " + dataObj.user },
          }
        )
        .then(function () {

          dataObj.loading = false;
          dataObj.approvesection = "success";
          dataObj.editingapprove = true;
          dataObj.response = "rejected";

          // return  response.data.loan_request[0];
        })
        .catch(function () {
          // // console.log("Response=" + JSON.stringify(error));

          dataObj.loading = false;
          dataObj.editingapprove = true;
          dataObj.response = "reject loan, Please refresh";
          dataObj.approvesection = "failed";

          return false;
        });
    },
    resetPaymentModal() {
      this.paymentmodal.id = "0";
      this.paymentmodal.date = "";
      this.paymentmodal.method = "cash";
      this.paymentmodal.produce = "";
      this.paymentmodal.quantity = 0;
      this.paymentmodal.unitPrice = 0;
      this.paymentmodal.totalAmount = 0;
      this.paymentmodal.units = "";
    },
    getCreditScore(dataObj) {
      dataObj.score_details=dataObj.dataloading;
      dataObj.credit_score=dataObj.dataloading;
      if (dataObj.loan['ma_id']==='NA' || dataObj.loan['ma_id']==='N/A' || !dataObj.loan['ma_id']){
        dataObj.getSMFarmerCreditScore(dataObj);
      }
      else{
        dataObj.getMaFarmerCreditScore(dataObj);
      }
    },
    getMaFarmerCreditScore(dataObj){
      dataObj.loading = true;

      axios
        .get(dataObj.uri + "api/v2/credit-score/", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            user_id: dataObj.loan["farmer_id"],
          },
        })
        .then(function (response) {
          // // console.log(response);
          dataObj.credit_score = response.data.total;
          dataObj.score_details = response.data;
          dataObj.loading = false;
        })
        .catch(function () {

          dataObj.loading = false;
        });
    },
    getSMFarmerCreditScore(dataObj){
      dataObj.loading = true;

      axios
        .get(dataObj.uri + "api/v2/credit-score/", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            user_id: dataObj.loan["farmer_id"],
          },
        })
        .then(function (response) {
          // // console.log(response);
          dataObj.credit_score = response.data.total;
          dataObj.score_details = response.data;
          dataObj.loading = false;
        })
        .catch(function () {

          dataObj.loading = false;
        });
    },
    getUserPayments(dataObj) {
      dataObj.loading = true;
      dataObj.payments = [];

      axios
        .get(dataObj.uri + "api/v2/user-payments/", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            user_id: dataObj.loan["farmer_id"],
          },
        })
        .then(function (response) {
          // // console.log(response);

          dataObj.payments = response.data.results[0].payments.filter((el)=> {
            return el != null;
          });
          dataObj.loan.balance = response.data.results[0].balance;
          dataObj.loading = false;
        })
        .catch(function () {
          dataObj.payments = dataObj.old;
          dataObj.loading = false;
        });
    },
    getUserTransactions(dataObj) {
      dataObj.loading = true;

      axios
        .get(dataObj.uri + "api/v2/credit/"+dataObj.loan["farmer_id"]+"/used", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params: {
            data: dataObj.loan["farmer_id"],
          },
        })
        .then(function (response) {
          // // console.log(response);

          // dataObj.score_details = response.data.score;
          dataObj.usage = response.data.message;
          dataObj.loading = false;
        })
        .catch(function () {

          dataObj.loading = false;
        });
    },
    getCoopData(dataObj){

      dataObj.loading = true;
      if(dataObj.loan.ma_id){
        axios
        .get(dataObj.uri +"api/v2/loans/coop", {
          headers: { Authorization: "Bearer " + dataObj.user },
          params:{
            ma_id:dataObj.loan["ma_id"]
          }
        })
        .then(function (response) {

          dataObj.coopData = response.data.message[0];
          dataObj.loading = false;
        })
        .catch(function () {
          dataObj.coopData= dataObj.defaultCoop;
          // console.log(dataObj.coopData);
          dataObj.loading = false;
        });
      }
      else{
        dataObj.coopData= dataObj.defaultCoop;
      }
      // console.log(dataObj.coopData);
      
    }
  },

  created() {
    bus.$on("showdetails", (payload) => {
      this.checkUser(this);
      this.loan = payload;
      // // console.log(this.loan);
      this.admin = sessionStorage.getItem('admin');
      this.getUserPayments(this);
      this.getUserTransactions(this);
      this.getCoopData(this);
      this.switchtab(this.loan.tab ? this.loan.tab : "details");

      this.admin?this.getCreditScore(this):null;
      this.$bvModal.show("details-modal");
      // // console.log(payload);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.label {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.75;
}

.val {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 12pt;
}

.loans-content {
  height: 90vh;
  max-height: 480pt;
  overflow: auto;
}

.tabs a {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  border-radius: 0pt;
  border-right: 4pt solid transparent;
  padding: 10pt 18pt;
  border-left: none;
  border-top: none;
  border-bottom: none;
  color: #666;
  cursor: pointer;
}

.tabs a.active {
  border-right-color: #3583c7;
  color: #3583c7;
  font-weight: bold;
  background-color: rgba(50, 78, 133, 0.1);
}

.left {
  background-color: rgba(230, 231, 233, 0.849);
}

.feather {
  position: relative;
  top: -1.7pt;
  vertical-align: middle;
  display: inline-block;
  height: 1em;
  margin-right: 3 px;
}

.app-card {
  .label {
    margin: 0;
    font-weight: 700;
    font-size: 10pt;
    opacity: 1;
  }

  .val {
    opacity: 0.75 !important;
    border-bottom: 1px dashed #999;
    padding-bottom: 5pt;
  }
}

.loan-summary {
  position: sticky;
  /*    background: #fff;
        box-shadow: 0 0 10pt rgba(0,0,0,0.1);*/
  top: 0;
  bottom: 0;

  .label {
    margin: 0;
    font-weight: 500;
    font-size: 1em;
    opacity: 1;
    color: #000;
  }

  .val {
    font-size: 1em;
    opacity: 0.75 !important;
    color: #2c3e50;
  }
}

.card-usage {
  background-color: #fff;
  padding: 10pt;

  border: 1px solid #aaa;
  border-left: 2px solid #87b7e7;
  border-radius: 3pt;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  p {
    font-weight: bold;
  }
  h5 {
    margin: 0;
    font-size: 1em;
  }
}

.m-title {
  font-size: 14pt;
  line-height: 25pt;
}
</style>
