<template>
  <div class="px-2">
    <div class="row mx-0 my-2">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Loans Dashboard</h1>
        </div>
      </div>
      <div class="col-auto pt-1">
        <!-- <loan-filter :curpage="2" /> -->
      </div>
    </div>
    <hr>

    <div class="row m-0 mb-3">
      <div class="col pr-2">
        <router-link to="/loans/approved" style="all:unset;" class="sublink">
        <div class="card accent">
          <span class="label">Total Amount Approved</span>
          <span class="value">{{totalAmount | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col pr-2">
        <div class="card used">
          <span class="label">Total Amount Received</span>
          <span class="value">{{totalAmount | addCommas}}</span>
        </div>
      </div>
      <div class="col pr-2">
        <div class="card accent">
          <span class="label">Total Amount Paid Back</span>
          <span class="value">{{totalPaid| addCommas}}</span>
        </div>
      </div>

      <div class="col-auto pl-2">
        <div class="card used">
          <span class="label">Farmers who paid back</span>
          <span class="value">{{paidBack | addCommas}}</span>
        </div>
      </div>
    </div>
    <hr>
    <div class="row m-0 mb-3">

      <div class="col-sm-2">
        <router-link to="/loans/applications" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Total Loan Applications</span>
          <span class="value">{{pending | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col-sm-2">
        <router-link to="/loans/active" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Loans Approved</span>
          <span class="value">{{approved | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col-sm-2">
        <router-link to="/loans/active" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Active Loans</span>
          <span class="value">{{active | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col-sm-2">
        <router-link to="/loans/cleared" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Loans paid back</span>
          <span class="value">{{cleared | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col-sm-2">
        <router-link to="/loans/active" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Loans Overdue</span>
          <span class="value">{{overdue | addCommas}}</span>
        </div>
        </router-link>
      </div>
      <div class="col-sm-2">
        <router-link to="/loans/rejected" style="all:unset;" class="sublink">
        <div class="card">
          <span class="label en black">Loans Rejected</span>
          <span class="value">{{rejected | addCommas}}</span>
        </div>
        </router-link>
      </div>
    </div>
    <hr>

    <div class="row m-0 mb-3">
      <div class="col-8">
        <div class="card graph h-100 p-0">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4>Loan Payments</h4>
              </div>
              <div class="col-auto">
                <router-link to="/loans/payments" class="sublink">View all</router-link>
              </div>
            </div>
          </div>
          <ve-line :data="chartData" height="350px" :events="chartEvents"></ve-line>
        </div>
      </div>
      <div class="col pl-2">
        <div class="card p-0 graph h-100">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4>Recent Payments</h4>
              </div>
              <div class="col-auto">
                <router-link to="/loans/payments" class="sublink">View all</router-link>
              </div>
            </div>
          </div>
          <div class="card-body scroll p-0">
            <table class="w-100 table-stripes table">
              <tr v-for="(loan,index) in newapplications" :key="index">
                <td width="1px" class="txt-primary">{{index + 1}}</td>
                <td class="text-muted">{{loan.farmer_name}}</td>
                <td>
                  <b>{{loan.amount | addCommas}}</b>
                </td>
                <td class="text-muted">{{loan.date | moment}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0 mb-5">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h4>Cooperatives</h4>
            </div>
            <div class="col-auto">
              <router-link to="/loans/applications" class="sublink">View Active loans</router-link>
            </div>
          </div>
        </div>
        <div class="card-body scroll p-0">
          <table class="w-100 table-stripes table">
            <thead>
              <th></th>
              <th>Coop Name</th>
              <th>Pending(UGX)</th>
              <th class="text-right">Approved(UGX)</th>
              <th class="t">Closed(UGX)</th>
              <th width="1px">Rejected(UGX)</th>
            </thead>
            <tbody>
              <tr v-for="(coop,index) in cooperatives" :key="index">
                <td width="1px" class="txt-primary">{{index + 1}}</td>
                <td class="text-muted">{{(coop.ma_name.length>13?coop.ma_name.substring(0, 12)+'...':coop.ma_name) | capitalize}}</td>
                <td class>
                  <b class="txt-primary">{{coop.pending | addCommas}}</b>
                </td>
                <td class>
                  <b class="txt-primary">{{coop.approved | addCommas}}</b>
                </td>
                <td class>
                  <b class="txt-primary">{{coop.closed | addCommas}}</b>
                </td>
                <td class="text-muted">
                  {{coop.rejected | addCommas}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <loader :isLoading="loading" />
  </div>
</template>

<script>
import Tables from "../mixins/Tables";
// import LoanFilter from "../components/LoanFilter";
import Loader from "../components/Loader";
import axios from "axios";
import { bus } from "../main.js";

export default {
  name: "LoansDashboard",
  props: {
    maID: String,
  },
  components: {  Loader },
  mixins: [Tables],
  data() {
    return {
      loading: false,
      totalAmount: 0,
      totalPaid: 0,
      paidBack: 0,
      pending: 0,
      approved: 0,
      cleared: 0,
      active: 0,
      overdue: 0,
      rejected: 0,
      newapplications: [],


      chartData: {
        columns: ["date", "amount"],
        rows: [],
      },
      cooperatives: [],
    };
  },
  mounted: function () {
    this.checkUser(this);
    this.getStats(this);
    this.$nextTick(function () {
      this.setTableHeight("holder", this.holderstyles);
    });
  },
  methods: {
    showDetails(index) {
      //alert(JSON.stringify(this.credits[index]))
      this.itemSelected = this.credits[index];
      bus.$emit("show-credit-details", this.itemSelected);
    },
    getStats(dataObj) {
      dataObj.loading = true;

      axios
        .get(process.env["VUE_APP_BACKEND_URL"] + "api/v2/loans/stats", {
          headers: { Authorization: "Bearer " + dataObj.user },
        })
        .then(function (response) {
          dataObj.newapplications = response.data.recent_pending;
          dataObj.chartData.rows = response.data.approved_loans;
          dataObj.cooperatives = response.data.coop_data;
          dataObj.approved = response.data.approved;

          dataObj.totalAmount = response.data.total_amount;
          dataObj.pending = response.data.pending;
          dataObj.rejected = response.data.rejected;
          dataObj.active = response.data.active;
          dataObj.paidBack = response.data.who_paid_back;
          dataObj.totalPaid = response.data.total_paid_back;
          dataObj.overdue = response.data.overdue;
          dataObj.cleared = response.data.cleared;


          dataObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          dataObj.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
  height: 360px;
  overflow: auto;
}
.card > .en{
  font-size: small;
}
.black{
  color: black;
}
.card.accent {
  color: #fff;
  background-color: #d94a8d;
  border-color: #d94a8d;
}

.card.accent .value {
  color: #fff;
}

.card.used {
  background-color: #edf3f6;
  border-color: #42768a;
  color: #42768a;
}

.card.used .value {
  color: #42768a;
}
</style>
