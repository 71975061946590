<template>
  <div class="row filter">
    <!-- <div class="col-auto pr-1">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Season:</span>
        </div>

        <input class="drop date-range-picker form-control" />
      </div>
    </div>-->

    <div class="col-auto px-8">
      <div class="row">
        <div class="col-auto pr-0">
          <span class="text">Period:</span>
        </div>
        <div class="col">
          <v-date-picker
            mode="range"
            format="DD-MM-YYYY"
            v-model="selector.dates"
            :input-props="{
              class: 'form-control',
            }"
          />

          <!-- <input class="drop date-range-picker form-control d-inline-block" /> -->
        </div>
      </div>
    </div>
    
    <div v-show="admin" class="col-auto pl-1">
      <div class="row">
        <div class="col-auto">
          <span class="text">Partner:</span>
        </div>
        <div class="col">
          <select class="form-control" v-model="selector.partner">
            <option selected="selected" value="All Partners">
              All Partners
            </option>
            <option
              v-for="(item, index) in partners"
              :key="index"
              :value="item.fi_id"
            >
              {{ item.fi_name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-show="!filters.cred" class="col-auto pl-1">
      <div class="row">
        <!-- <div class="col-auto">
          <span class="text">District:</span>
        </div>-->
        <div class="col">
          <select class="form-control" v-model="selector.district">
            <option selected="selected" value="All districts">
              All districts
            </option>
            <option
              v-for="(item, index) in districts"
              :key="index"
              :value="index"
            >
              {{ index }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-show="filters.cred" class="col-auto pl-6">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Tables from "../mixins/Tables";

export default {
  name: "LoanFilter",
  props: {
    filters: {
      type: Object,
      default: () => {
        return {
          district: "All districts",
          partner: "",
          dates: {
            start: new Date(2020, 0, 1),
            end: new Date(),
          },
        };
      },
    },
    curpage: String,
  },
  mixins: [Tables],
  data() {
    return {
      districts: [],
      uri: process.env["VUE_APP_BACKEND_URL"],
      partners: [],
      admin: sessionStorage.getItem("admin"),
      selector:{district: "All districts",
      dates: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      partner:'',
    }
    };
  },
  methods: {
    getFilter() {
      this.partners = [];
      // set date
      var date = new Date();
      this.selector.dates.start = new Date(
        date.getFullYear() - 3,
        date.getMonth() - 2,
        1
      );
    },
    getDistricts() {
      this.loading = true;
      axios
        .get(this.uri + "api/v2/districts", {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then( (response)=> {
          this.districts = response.data.districts;
          // console.log(dataObj.districts)
          // console.log(dataObj.loans);
          this.loading = false;
        })
        .catch( (error) =>{
          console.log(error);
          this.loading = false;
        });
    },

    getPartners() {
      // Get all partners if 
      this.loading = true;
      axios
        .get(this.uri + "api/v2/partners-all", {
          headers: { Authorization: "Bearer " + this.user },
        })
        .then( (response) =>{
          this.partners = response.data;
          this.loading = false;
        })
        .catch( (error) =>{
          console.log(error);
          this.loading = false;
        });
    },

    updateFilter() {
      this.$emit("updateFilter",this.selector);
    },
  },
  created() {
    this.checkUser(this);
    this.getFilter();
    this.getDistricts();
    this.updateFilter();
    this.admin = sessionStorage.getItem("admin");
    this.admin?this.getPartners():null;
  },
    watch:{
selector:{
  handler(){
    this.updateFilter();
  },
  deep:true
},}
};
</script>

<style scoped lang="scss">
.text {
  display: inline-block;
  vertical-align: middle;
  padding: 0.3em;
  font-weight: 800;
  margin-bottom: 0;
}

.date-range-picker {
  width: 240px;
}

.filter {
  .form-control {
    font-size: 10pt;
    font-size: 12pt;
    border: none;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.22) !important;

    // background: none !important;
    // border: none !important;
    font-weight: 500;
  }
}
</style>