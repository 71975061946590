<template>
  <div class="px-2">
    <div class="row mx-0 my-2">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Ezy Credits dashboard</h1>
        </div>
      </div>
      <div class="col-auto pt-1">

      </div>
    </div>
    <hr>
    <div class="row m-0 mb-3">
      <div class="col pr-2">
        <div class="card accent">
          <span class="label">Credits bought</span>
          <span class="value">{{bought | addCommas}}</span>
        </div>
      </div>
      <div class="col px-2">
        <div class="card used">
          <span class="label">Credits used</span>
          <span class="value">{{used | addCommas}}</span>
        </div>
      </div>
      <div class="col px-2">
        <div class="card">
          <span class="label">Unused credits</span>
          <span class="value">{{unused | addCommas}}</span>
        </div>
      </div>
      <div class="col-auto pl-2">
        <div class="card">
          <span class="label">Farmers with credits</span>
          <span class="value">{{farmers | addCommas}}</span>
        </div>
      </div>
    </div>

    <div class="row m-0 mb-3">
      <div class="col-8">
        <div class="card graph h-100 p-0">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4>Ezy Credits purchased recently</h4>
              </div>
              <div class="col-auto">
                <router-link to="/credits/bought/" class="sublink">View all</router-link>
              </div>
            </div>
          </div>
          <ve-line :data="chartData" height="350px" :events="chartEvents"></ve-line>
        </div>
      </div>
      <div class="col pl-2">
        <div class="card p-0 graph h-100">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4>Ezy Credits purchased recently</h4>
              </div>
              <div class="col-auto">
                <router-link to="/credits/bought/" class="sublink">View all</router-link>
              </div>
            </div>
          </div>
          <div class="card-body scroll p-0">
            <table class="w-100 table-stripes table">
              <tr v-for="(credit,index) in newcredits" :key="index">
                <td width="1px" class="txt-primary">{{index + 1}}</td>
                <td class="text-muted">{{credit.farmer_name}}</td>
                <td>
                  <b>{{credit.amount}}</b>
                </td>
                <td class="text-muted">{{credit.date | moment}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0 mb-5">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h4>Top credits users</h4>
            </div>
            <div class="col-auto">
              <router-link to="/credits/bought/" class="sublink">View all</router-link>
            </div>
          </div>
        </div>
        <div class="card-body scroll p-0">
          <table class="w-100 table-stripes table">
            <thead>
              <th></th>
              <th>Name</th>
              <th>Phone</th>
              <th class="text-right">Used Credits</th>
              <th class="t">Credits Balance</th>
              <th width="1px"></th>
            </thead>
            <tbody>
              <tr v-for="(credit,index) in credits" :key="index">
                <td width="1px" class="txt-primary">{{index + 1}}</td>
                <td class="text-muted">{{(credit.farmer_name.length>13?credit.farmer_name.substring(0, 12)+'...':credit.farmer_name) | capitalize}}</td>
                <td class="text-muted">0748839117</td>
                <td class="text-right">
                  <b>{{credit.used}}</b>
                </td>
                <td class>
                  <b class="txt-primary">{{credit.balance}}</b>
                </td>
                <td class="text-muted">
                  <button
                    class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                    @click="showDetails(index)"
                  >View details</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <loader :isLoading="loading" />
  </div>
</template>

<script>
import Tables from "../mixins/Tables";
import Loader from "../components/Loader";
import axios from "axios";
import { bus } from "../main.js";

export default {
  name: "CreditsDashboard",
  props: {
    maID: String,
  },
  components: { Loader },
  mixins: [Tables],
  data() {
    return {
      loading: false,
      unused: 0,
      used: 0,
      bought: 0,
      farmers: 0,
      chartEvents:null,
      chartData: {
        columns: ["date", "credits"],
        rows: [],
      },
      newcredits: [],
      credits: [],
    };
  },
  mounted: function () {
    this.checkUser(this);
    this.getStats(this);
    this.$nextTick(function () {
      this.setTableHeight("holder", this.holderstyles);
    });
  },
  methods: {
    showDetails(index) {
      //alert(JSON.stringify(this.credits[index]))
      this.itemSelected = this.credits[index];
      bus.$emit("show-credit-details", this.itemSelected);
    },
    getStats(dataObj) {
      dataObj.loading = true;

      axios
        .get(process.env["VUE_APP_BACKEND_URL"] + "api/v2/credit/stats", {
          headers: { Authorization: "Bearer " + dataObj.user },
        })
        .then(function (response) {
          dataObj.newcredits = response.data.credits.recent;
          dataObj.chartData.rows = response.data.credits.stats;
          dataObj.credits = response.data.credits.credit;
          dataObj.bought = response.data.credits.totals.bought;
          dataObj.farmers = response.data.credits.totals.farmers;
          dataObj.unused = response.data.credits.totals.unused;
          dataObj.used = response.data.credits.totals.used;

          dataObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          dataObj.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
  height: 360px;
  overflow: auto;
}

.card.accent {
  color: #fff;
  background-color: #d94a8d;
  border-color: #d94a8d;
}

.card.accent .value {
  color: #fff;
}

.card.used {
  background-color: #edf3f6;
  border-color: #42768a;
  color: #42768a;
}

.card.used .value {
  color: #42768a;
}
</style>
