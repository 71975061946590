<template>
    <div class="px-2">
      <div class="row mx-0 my-2 filter">
        <div class="col">
          <div class="h-100 d-flex align-items-center">
            <h1 class="page-title m-0">Loans Issued ({{totalFarmers | addCommas}})</h1>
          </div>
        </div>
        <div class="col-auto">
          <loan-filter @updateFilter="updatefilter" :filters="filters" />
        </div>
      </div>
  
      <div class="col-12">
        <div class="card p-0">
          <div class="card-header p-2">
            <div class="row">
              <season-filter @updateFilter="updatefilter" :filters="seasonfilter"/>
              <div class="col-auto pr-0">
                <input class="search form-control py-0" placeholder="search" v-model="searchQuery" @keyup="onSearch"/>
              </div>
              <div class="col-auto">
                <div class="col-auto">
              <export-options
                page="ActiveLoans"
                :parameters="parameters"
                :url="`${pageUrl}/download`"
                :loading="loading"
              />
            </div>
              </div>
              <div class="col-auto">
                <button @click="reload"><b-icon-arrow-clockwise></b-icon-arrow-clockwise>Refresh</button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-holder" ref="holder" :style="holderstyles">
              <table class="w-100 table-stripes table h" ref="table">
                <thead>
                  <th></th>
                  <!-- <th>Loan ID</th> -->
                  <th>Name</th>
                  <th>Principal</th>
                  <th class="text-right">Fee</th>
                  <th class="text-right">Total loan</th>
  
                  <th class="text-right">Paid</th>
  
                  <th class="text-right">Total Balance</th>
                  <th class="text-right" width="1px"></th>
                  <th class>Due date</th>
                  <th class>Contact</th>
                  <th width="1px"></th>
                </thead>
                <tbody>
                  <!-- <tr v-infinite-scroll="getActiveLoans()" infinite-scroll-disabled="loading" infinite-scroll-distance="0" infinite-scroll-throttle-delay="500"> -->
                  <tr v-for="(loan,index) in activeloans" :key="index" >
                     <!-- {{ activeloans }} -->
                    <td width="1px" class>{{index + 1}}</td>
                    <!-- <td class="txt-primary">{{loan.id}}</td> -->
                    <td class="txt-primary">{{(loan.name.length>13?loan.name.substring(0, 12)+'...':loan.name) | capitalize }}</td>
                    <td class="text-muted">{{loan.amount | addCommas}}</td>
                    <td width="1px" class>
                      <span class="bg-accent p-1 text-white rounded">{{loan.interest | addCommas}}</span>
                    </td>
                    <td class="text-right">
                      <b>{{loan.total | addCommas}}</b>
                    </td>
                    
                    <td class="text-right">
                      <b class="txt-primary">{{loan.paid | addCommas}}</b>
                    </td>
                    <td class="text-right">
                      <b class="txt-primary">{{loan.balance | addCommas}}</b>
                    </td>
                    <td class="text-right">
                      <span class="dot"></span>
                    </td>
                    <td class>
                      <span class="txt-primary">{{loan.due_date | moment}}</span>
                    </td>
                    <td class>
                      <b class>{{loan.contact | contact }}</b>
                    </td>
  
                    <td class="text-muted">
                      <button
                        class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                        @click="showLoanDetails(loan)"
                      >View details</button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="11" style="background:white;">
                  <infinite-loading :identifier="infiniteId" @infinite="getActiveLoans"></infinite-loading>
                    </td>
                  </tr>
  
                </tbody>
              </table>
            </div>
          </div>
  
          <div class="card-footer">
            <div class="row">
              <div class="col-auto">
                <span class="txt-primary">ACTIVE LOANS:</span>
                <b>{{totalFarmers | addCommas}}</b>
              </div>
  
              <div class="col-auto">
                <span class="txt-primary">TOTAL LOAN AMOUNT:</span>
                <b>{{totalLoanAmount | addCommas}}</b>
              </div>
  
              <div class="col-auto">
                <span class="text-danger">TOTAL LOAN BALANCE:</span>
                <b>{{totalLoanBalance | addCommas}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loader :isLoading="loading" />
      <!--modal-->
    </div>
  </template>
  
  <script>
  import Tables from "../mixins/Tables";
  import { bus } from "../main.js";

  import LoanFilter from "../components/LoanFilter";
  import SeasonFilter from "../components/SeasonFilter";
  
  import ExportOptions from "../components/ExportOptions";
  
  import Loader from "../components/Loader";
  
  import axios from "axios";
  
  export default {
    name: "LoansActive",
    components: {  LoanFilter,SeasonFilter,  Loader, ExportOptions },
    props: {
      maID: String,
    },
    mixins: [Tables],
    data() {
      return {
        activeloans: [],
        page:1,
        searchQuery:'',
        totalFarmers:0,
        totalLoanAmount: 0,
        loading:false,
        totalLoanBalance: 0,
        infiniteId: +new Date(),
        filters:{
        
  
          district: "All districts",
          partner: "",
          dates: {
          start: new Date(),
          end:  new Date(),
          
        }},
        coopfilter:{
          farmerType:'',
          cooperative:''
        },
        seasonfilter:{
          season:'',
          page:'All Active Loans'
        },
        uri:process.env['VUE_APP_BACKEND_URL'],
        pageUrl: process.env['VUE_APP_BACKEND_URL']+"api/v3/loans/pezesha/active",
        user: null,
        holderstyles: {
          height: "0px",
        },
        loanSelected: [],
        parameters:{},
      };
    },

      
    created() {
      this.reload()
    },
  
    methods: {
      
        reload(){
            this.checkUser(this);
            this.getTotals();
            this.busy = true;
            this.onSearch();
            this.$nextTick(()=> {
            this.setTableHeight("holder", this.holderstyles);
    });
        },
      showLoanDetails(loan,) {
        // this.getLoan(index,);
        bus.$emit("showloandetails", loan)
      },
      
  
      getActiveLoans($state) {
  
        this.parameters={
          page: this.page,
          name: this.searchQuery,
          district: this.filters.district==='All districts'?'':this.filters.district,
          start: this.formatDate(this.filters.dates.start),
          end:this.formatDate(this.filters.dates.end),
          season:this.seasonfilter.season,
        }   
        axios
          .get(this.pageUrl, {
            headers:{'Authorization': 'Bearer ' + this.user},
            params: this.parameters,
  
          })
          .then((response) =>{
  
            this.page++;
            if(response.data.length>=25){
              this.activeloans = this.activeloans.concat(response.data);
              $state.loaded();
            }
            else if(response.data.length>0) {
              this.activeloans = this.activeloans.concat(response.data);
              // this.loading = false;
              $state.loaded();
              throw false;
            }
            else{
              throw false;
            }
            
          })
          .catch( (error)=> {
            console.log(error);
            // this.loading = false;
            $state.complete();
          });
  
  
      },
      getTotals(){
        this.loading  = true;
        axios
          .get(this.uri+"api/v2/loans-totals/", {
            headers:{'Authorization': 'Bearer ' + this.user},
          })
          .then( (response)=> {
  
  
            this.loading = false;
            this.totalFarmers=response.data[0].farmers;
            this.totalLoanAmount=response.data[0].totals.total;
            this.totalLoanBalance=response.data[0].totals.balance;
            
          })
          .catch((error)=> {
            console.log("Response=" + JSON.stringify(error));
            this.loading = false;
            return false;
          });
      },
      onSearch(){
        this.page = 1;
        this.parameters={
              page: this.page,
              name: this.searchQuery,
              district: this.filters.district==='All districts'?'':this.filters.district,
              start: this.formatDate(this.filters.dates.start),
              end:this.formatDate(this.filters.dates.end),
              season:this.seasonfilter.season,
        }   
        
        this.activeloans = [];
        this.infiniteId += 1;
  
      },
  
    },
    watch:{
      loanSelected(){
        // console.log('change', x);
      },
  
      filters:{
        handler(){
          this.onSearch();
        },
        deep:true
        
      },
      seasonfilter:{
        handler(){
          this.onSearch();
        },
        deep:true
      }
    },
  
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .dot {
    height: 10pt;
    width: 10pt;
    border-radius: 10pt;
    background-color: #e8e8e8;
    display: inline-block;
  }
  </style>
  