<template>
  <div id="app" :class="blurClass">
    <div v-if="$route.path != '/login'" class="appbar">
      <div class="row m-0">
        <div class="col-12 col-md-auto pl-0 p-0">
          <div class="p-left"></div>
        </div>
        <div class="col-12 col-md border-left">
          <div class="p-right w-100">
            <div class="row">
              <div class="col-md col-12">
                <div class="bcrumbs">
                  <router-link
                    to="/loans/active"
                    class="txt-primary"
                  >{{this.$router.currentRoute.path}}</router-link>
                  <chevron-right-icon />
                  <span class="text-muted">Active</span>
                </div>
              </div>
              <div class="col-md-auto col-12">
                <span
                  class="border-right pr-2 mr-2"
                  style="border-color:#999 !important; color:#777"
                >Akorion</span>
                <b class="txt-primary">{{username}}</b>

                <button 
                class="btn-light mx-3 mt-0" 
                style="border-color:#999"
                @click="logout()"
                >
                  <log-out-icon />Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
    <div v-if="$route.path != '/login'" class="col-auto side-menu">
      <div class="menu">
        <router-link to="/loans/active" class="mainlink">Loans</router-link>
        <!-- <router-link to="/loans/" class="sublink">
          <bar-chart-2-icon />Dashboard
        </router-link>-->
        <router-link to="/loans/dashboard" class="sublink">
          <bar-chart-2-icon /> Dashboard
          <!-- <span class="link-badge float-right">{{active}}</span> -->
        </router-link>
        <router-link to="/loans/active" class="sublink">
          <activity-icon />Active loans
          <span class="link-badge float-right">{{active}}</span>
        </router-link>

        <router-link to="/loans/approved" class="sublink">
          <activity-icon />Approved loans
          <span class="link-badge float-right">{{approved}}</span>
        </router-link>
        <router-link to="/loans/payments" class="sublink">
          <activity-icon />Loan Payments
          <span class="link-badge float-right">{{payments}}</span>
        </router-link>
        <router-link to="/loans/usage" class="sublink">
          <activity-icon />Loan Usage
        </router-link>
        <router-link to="/loans/applications/" class="sublink">
          <file-plus-icon />Applications
          <span class="link-badge float-right bg-danger">{{applications}}</span>
        </router-link>

        <router-link to="/loans/cleared" class="sublink">
          <check-circle-icon />Cleared loans
          <span class="link-badge float-right">{{cleared}}</span>
        </router-link>

        <router-link to="/loans/rejected/" class="sublink">
          <x-octagon-icon />Rejected loans
          <span class="link-badge float-right">{{rejected}}</span>
        </router-link>
        
      <div v-if="admin">
        <router-link to="/loans/pezesha" class="mainlink">Pezesha</router-link>
        <router-link to="/loans/pezesha" class="sublink accent">
           <bar-chart-2-icon />
        Loans Issued </router-link>
       <router-link to="/loans/pezesha/users" class="sublink accent">
           <users-icon />
       Registered Users </router-link>
       <router-link to="/loans/pezesha/offers" class="sublink accent">
        <file-plus-icon />
        Loan Offers</router-link>
       <router-link to="/loans/pezesha/users/failed" class="sublink accent">
           <users-icon />
       Failed Logs</router-link>
        <router-link to="/credits/" class="mainlink">Ezy Credits</router-link>
        <router-link to="/credits/" class="sublink accent">
           <bar-chart-2-icon />
       Dashboard </router-link>
        <router-link to="/credits/farmers/" class="sublink accent">
          <users-icon />Farmers
          <span class="link-badge float-right">{{creditsFarmerCount}}</span>
        </router-link>
        <router-link to="/credits/usage/" class="sublink accent">
          <droplet-icon />Credits usage
        </router-link>
        <router-link to="/credits/bought/" class="sublink accent">
          <disc-icon />Credits Bought
        </router-link>
        <router-link to="/credits/transactions/" class="sublink accent">
          <disc-icon />Mobile Transactions
        </router-link>
      </div>
      </div>
    </div>

    <div class="col main-content">
      <router-view></router-view>
      <loan-details></loan-details>
      <credit-details></credit-details>

      <agg-loan-details></agg-loan-details>
      <agg-user-details></agg-user-details>
      <agg-create-user :districts="districts"></agg-create-user>
    </div>
    </div>
  </div>
</template>

<script>
import { bus } from "./main";

import LoanDetails from "./components/LoanDetails";
import AggLoanDetails from "./components/aggLoanDetail/Details";
import AggUserDetails from "./components/aggLoanDetail/UserDetails";
import AggCreateUser from "./components/aggLoanDetail/CheckUser";
import CreditDetails from "./components/CreditDetails";
import {
  BarChart2Icon,
  ActivityIcon,
  FilePlusIcon,
  XOctagonIcon,
  CheckCircleIcon,
  DropletIcon,
  UsersIcon,
  DiscIcon,
  LogOutIcon,
  ChevronRightIcon,
} from "vue-feather-icons";
import axios from "axios";

export default {
  name: "App",
  components: {
    LoanDetails,
    CreditDetails,
    AggLoanDetails,
    ChevronRightIcon,
    AggUserDetails,
    AggCreateUser,

    /*icons*/
    BarChart2Icon,
    ActivityIcon,
    FilePlusIcon,
    XOctagonIcon,
    CheckCircleIcon,
    DropletIcon,
    UsersIcon,
    DiscIcon,
    LogOutIcon,
    //            VueFeatherIcons
  },
  data() {
    return {
      creditsFarmerCount: 0,
      active:0,
      applications:0,
      cleared:0,
      rejected:0,
      approved:0,
      payments:0,
      loanApplicationCount: 0,
      loanSelected: [],
      blurClass: "",
      username: '',
      login: process.env.VUE_APP_LOGIN_URL,
      url: process.env.VUE_APP_BACKEND_URL,
      districts:[],
      admin: false,
      token:null,
      currentRoute: this.$router.currentRoute.path
    };
  },
  methods: {
    // checkUser(dataObj){
    //   sessionStorage.getItem('token')?(dataObj.user = sessionStorage.getItem('token')): dataObj.$router.push('/');
    // },
    logout() {
      sessionStorage.clear();
      (this.$router.currentRoute.path!=='/')?
      this.$router.push('/'):window.location.href = this.login;
    },
    getTotals(dataObj){
      axios
        .get(dataObj.url+"api/v2/loans/totals", {
          headers:{'Authorization': 'Bearer ' + dataObj.token},
        })
        .then(function (response) {


          dataObj.loading = false;
          // console.log(response.data.loan_requests[0])
          dataObj.active=response.data.message[0].active;
          dataObj.applications=response.data.message[0].applications;
          dataObj.approved=response.data.message[0].approved;
          dataObj.cleared=response.data.message[0].cleared;
          dataObj.rejected=response.data.message[0].rejected;
          dataObj.payments=response.data.message[0].payments;
          // dataObj.loanSelected.tab = 'application';
          // // console.log(dataObj.loanSelected);
          
        })
        .catch(function (error) {
          console.log(error)
          dataObj.loading = false;
          return false;
        });
    },
    getDistricts() {
      this.loading = true;
      axios
        .get(this.url + "api/v2/districts",)
        .then( (response)=> {
          this.districts = response.data.districts;
          this.loading = false;
        })
        .catch( (error) =>{
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    // this.checkUser(this);
    // console.log(sessionStorage)
    this.username = this.name;
    this.admin = sessionStorage.getItem('admin')
    bus.$on("showdetails", () => {
      this.blurClass = "blur";
    }),
      bus.$on("hidedetails", () => {
        this.blurClass = "";
      }),
      bus.$on("show-credit-details", () => {
        this.blurClass = "blur";
      }),
      bus.$on("hide-credit-details", () => {
        this.blurClass = "";
      });
    this.getDistricts();
  },
  watch:{
    $route (){
      this.admin = sessionStorage.getItem('admin')
      this.username = sessionStorage.getItem('user');
      this.token = sessionStorage.getItem('token');
      this.getTotals(this);
    }
  }
};
</script>

<style lang="scss">




</style>
