<template>
    <div class="px-2">
      <div class="row mx-0 my-2 filter">
        <div class="col">
          <div class="h-100">
            <h1 class="page-title m-0">Loan Offers ({{totalFarmers | addCommas}})</h1>
            <p class="text-secondary m-0">Please note that these are the offers as at the time displayed. Click the user to see the current offer.</p>
          </div>
        </div>
        <div class="col-auto">
          <loan-filter @updateFilter="updatefilter" :filters="filters" />
        </div>
      </div>
  
      <div class="col-12">
        <div class="card p-0">
          <div class="card-header p-2">
            <div class="row">
              <season-filter @updateFilter="updatefilter" :filters="seasonfilter"/>
              <partner-filter @updateFilter="updatefilter" :filters="coopfilter" />
              <div class="col-auto pr-0">
                <input class="search form-control py-0" placeholder="search" v-model="searchQuery" @keyup="onSearch"/>
              </div>
              <div class="col-auto">
              <button @click="reload">
                <b-icon icon="arrow-clockwise"></b-icon>&nbsp;Refresh
              </button>
            </div>
              <div class="col-auto">
                <export-options page="LoanOffers" :parameters="parameters" :url="downloadUrl" :loading="loading"/>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-holder" ref="holder" :style="holderstyles">
              <table class="w-100 table-stripes table h" ref="table">
                <thead>
                  <th></th>
                  <!-- <th>Loan ID</th> -->
                  <th>Name</th>
                  <th class>Contact</th>
                  <th>Principal</th>
                  <th class="text-right">Fee</th>
                  <th class="text-right">Total loan</th>
                  <th class="text-right" width="1px"></th>
                  <th class>Offer date</th>
                  <th width="1px"></th>
                </thead>
                <tbody>
                  <!-- <tr v-infinite-scroll="getLoanOffers()" infinite-scroll-disabled="loading" infinite-scroll-distance="0" infinite-scroll-throttle-delay="500"> -->
                  <tr v-for="(loan,index) in activeloans" :key="index" >
                     <!-- {{ activeloans }} -->
                    <td width="1px" class>{{index + 1}}</td>
                    <!-- <td class="txt-primary">{{loan.id}}</td> -->
                    <td class="txt-primary">{{(loan.farmer_name.length>13?loan.farmer_name.substring(0, 12)+'...':loan.farmer_name) | capitalize }}</td>
                    <td class>
                      <b class>{{loan.contact | contact }}</b>
                    </td>
                    <td class="text-muted">{{loan.amount | addCommas}}</td>
                    <td width="1px" class>
                      <span class="bg-accent p-1 text-white rounded">{{loan.interest | addCommas}}</span>
                    </td>
                    <td class="text-right">
                      <b>{{(loan.amount+loan.interest) | addCommas}}</b>
                    </td>
                    

                    <td class>
                      <span class="txt-primary">{{loan.time | dateandtime}}</span>
                    </td>
                   
  
                    <td class="text-muted">
                      <button
                        class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                        @click="showUserDetails(loan)"
                      >View details</button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="11" style="background:white;">
                  <infinite-loading :identifier="infiniteId" @infinite="getLoanOffers"></infinite-loading>
                    </td>
                  </tr>
  
                </tbody>
              </table>
            </div>
          </div>
  
        </div>
      </div>
      <loader :isLoading="loading" />
      <!--modal-->
    </div>
  </template>
  
  <script>
  import Tables from "../mixins/Tables";
  import { bus } from "../main.js";
  
  import PartnerFilter  from "../components/PartnerFilter";
  import LoanFilter from "../components/LoanFilter";
  import SeasonFilter from "../components/SeasonFilter";
  
  import ExportOptions from "../components/ExportOptions";
  
  import Loader from "../components/Loader";
  
  import axios from "axios";
  
  export default {
    name: "LoansActive",
    components: {  LoanFilter,SeasonFilter, PartnerFilter,  Loader, ExportOptions },
    props: {
      maID: String,
    },
    mixins: [Tables],
    data() {
      const pageUrl = process.env['VUE_APP_BACKEND_URL']+"api/v3/loans/pezesha/users/offers";
      const downloadUrl = `${pageUrl}/download`;
      return {
        pageUrl,
        downloadUrl,
        activeloans: [],
        page:1,
        searchQuery:'',
        totalFarmers:0,
        totalLoanAmount: 0,
        loading:false,
        totalLoanBalance: 0,
        infiniteId: +new Date(),
        filters:{
          district: "All districts",
          partner: "",
          dates: {
          start: new Date(),
          end:  new Date(),
        }},
        coopfilter:{
          farmerType:'',
          cooperative:''
        },
        seasonfilter:{
          season:'',
          page:'All Active Loans'
        },
        uri:process.env['VUE_APP_BACKEND_URL'],
        
        user: null,
        holderstyles: {
          height: "0px",
        },
        loanSelected: [],
        parameters:{},
      };
    },
    created() {
      this.checkUser(this);
      this.$nextTick( ()=> {
        this.setTableHeight("holder", this.holderstyles);
      });
    },
  
    methods: {
      reload(){
       this.onSearch();
      },
  
      showUserDetails(user) {
      // this.getLoan(index,);
        bus.$emit("showUserDetails", user);
      },
      
  
      getLoanOffers($state) {
  
        this.parameters={
          page: this.page,
          name: this.searchQuery,
          district: this.filters.district==='All districts'?'':this.filters.district,
          start: this.formatDate(this.filters.dates.start),
          end:this.formatDate(this.filters.dates.end),
          maID:this.coopfilter.cooperative,
          farmerType:this.coopfilter.farmerType,
          season:this.seasonfilter.season,
          partner: this.filters.partner==='All Partners'?'':this.filters.partner,
        }   
        axios
          .get(this.pageUrl, {
            headers:{'Authorization': 'Bearer ' + this.user},
            params: this.parameters,
  
          })
          .then((response)=> {
  
            this.page++;
            if(response.data.length>0){
              this.activeloans = this.activeloans.concat(response.data);
              $state.loaded();
            }
            else{
              throw false;
            }
          })
          .catch( (error)=> {
            console.log(error);
            $state.complete();
          });
  
  
      },
      getLoan(id,){
        this.loading  = true;
      
  
  
        axios
          .get(this.uri+"api/v2/loans/"+id+"/recent/", {
            headers:{'Authorization': 'Bearer ' + this.user},
          })
          .then((response)=> {
            this.loading = false;
            this.loanSelected=response.data.loan_request[0];
            bus.$emit("showdetails", this.loanSelected)
          })
          .catch((error) =>{
            console.log("Response=" + JSON.stringify(error));
            this.loading = false;
            return false;
          });
      },
      getTotals(){
        this.loading  = true;
        axios
          .get(this.uri+"api/v2/loans-totals/", {
            headers:{'Authorization': 'Bearer ' + this.user},
          })
          .then((response)=> {
  
            this.loading = false;
            // console.log(response.data.loan_requests[0])
            this.totalFarmers=response.data.loan_requests[0].farmers;
            this.totalLoanAmount=response.data.loan_requests[0].totals.total;
            this.totalLoanBalance=response.data.loan_requests[0].totals.balance;
            // this.loanSelected.tab = 'application';
            // // console.log(this.loanSelected);
            
          })
          .catch( (error)=> {
            console.log("Response=" + JSON.stringify(error));
            this.loading = false;
            return false;
          });
      },
      onSearch(){
        this.page = 1;
        this.parameters={
          page: this.page,
          name: this.searchQuery,
          district: this.filters.district==='All districts'?'':this.filters.district,
          start: this.formatDate(this.filters.dates.start),
          end:this.formatDate(this.filters.dates.end),
          maID:this.coopfilter.cooperative,
          farmerType:this.coopfilter.farmerType,
          season:this.seasonfilter.season,
          partner: this.filters.partner==='All Partners'?'':this.filters.partner,
        }   
        
        this.activeloans = [];
        this.infiniteId += 1;
  
      },
  
    },
    watch:{
      loanSelected(){
        // console.log('change', x);
      },
  
      filters:{
        handler(){
          this.onSearch();
        },
        deep:true
        
      },
      coopfilter:{
        handler(){
          this.onSearch();
        },
        deep:true
      },
      seasonfilter:{
        handler(){
          this.onSearch();
        },
        deep:true
      }
    },
  
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .dot {
    height: 10pt;
    width: 10pt;
    border-radius: 10pt;
    background-color: #e8e8e8;
    display: inline-block;
  }
  </style>
  