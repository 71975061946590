<template>
  <div class="px-2">
    <div class="row mx-0 my-2 filter">
      <div class="col">
        <div class="h-100 d-flex align-items-center">
          <h1 class="page-title m-0">Registered Users</h1>
        </div>
      </div>
      <div class="col-auto">
        <loan-filter @updateFilter="updatefilter" :filters="filters" />
      </div>
    </div>

    <div class="col-12">
      <div class="card p-0">
        <div class="card-header p-2">
          <div class="row">
            <season-filter @updateFilter="updatefilter" :filters="seasonfilter" />
            <div class="col-auto pr-0">
              <input
                class="search form-control py-0"
                placeholder="search"
                v-model="searchQuery"
                @keyup="onSearch"
              />
            </div>
            <div class="col-auto">
              <export-options
                page="RegisteredUsers"
                :parameters="parameters"
                :url="`${pageUrl}/download`"
                :loading="loading"
              />
            </div>
            <div class="col-auto">
              <button @click="reload">
                <b-icon icon="arrow-clockwise"></b-icon>&nbsp;Refresh
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-holder" ref="holder" :style="holderstyles">
            <table class="w-100 table-stripes table h" ref="table">
              <thead>
                <th></th>
                <!-- <th>Loan ID</th> -->
                <th>Name</th>
                <th class>Contact</th>
                <!-- <th>Loan Offer</th> -->
                <th class>Email</th>
                <th class>Date</th>
                <th width="1px"></th>
              </thead>
              <tbody>
                <!-- <tr v-infinite-scroll="getUserLogs()" infinite-scroll-disabled="loading" infinite-scroll-distance="0" infinite-scroll-throttle-delay="500"> -->
                <tr v-for="(loan, index) in registeredusers" :key="index">
                  <!-- {{ registeredusers }} -->
                  <td width="1px" class>{{ index + 1 }}</td>
                  <!-- <td class="txt-primary">{{loan.id}}</td> -->
                  <td class="txt-primary">
                    {{
                      (loan.farmer_name.length > 13
                        ? loan.farmer_name.substring(0, 12) + "..."
                        : loan.farmer_name) | capitalize
                    }}
                  </td>

                  <td class>
                    <b class>{{ loan.contact | contact }}</b>
                  </td>
                  <td class="text-muted">
                    {{ loan.email }}
                  </td>
                  <td class="text-muted">
                    {{ loan.time | moment }}
                  </td>

                  <td class="text-muted">
                    <button
                      class="btn btn-rounded btn-sm m-0 btn-outline-dark"
                      @click="showUserDetails(loan)"
                    >
                      View details
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="11" style="background:white;">
                    <infinite-loading
                      :identifier="infiniteId"
                      @infinite="getUserLogs"
                    ></infinite-loading>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="row"></div>
        </div>
      </div>
      <loader :isLoading="loading" />
      <!--modal-->
      <user-logs></user-logs>
    </div>
  </div>
</template>

<script>
import Tables from "../mixins/Tables";
import { bus } from "../main.js";

import LoanFilter from "../components/LoanFilter.vue";
import SeasonFilter from "../components/SeasonFilter.vue";

import ExportOptions from "../components/ExportOptions.vue";

import Loader from "../components/Loader.vue";
import UserLogs from "../components/aggLoanDetail/FailedLogs.vue"

import axios from "axios";

export default {
  name: "LoansActive",
  components: { LoanFilter, SeasonFilter, Loader, ExportOptions, UserLogs },
  props: {
    maID: String,
  },
  mixins: [Tables],
  data() {
    return {
      registeredusers: [],
      page: 1,
      searchQuery: "",
      totalFarmers: 0,
      totalLoanAmount: 0,
      loading: false,
      totalLoanBalance: 0,
      infiniteId: +new Date(),
      filters: {
        district: "All districts",
        partner: "",
        dates: {
          start: new Date(),
          end: new Date(),
        },
      },
      coopfilter: {
        farmerType: "",
        cooperative: "",
      },
      seasonfilter: {
        season: "",
        page: "All Active Loans",
      },
      uri: process.env["VUE_APP_BACKEND_URL"],
      pageUrl:
        process.env["VUE_APP_BACKEND_URL"] + "api/v3/loans/pezesha/user/failed",
      user: null,
      holderstyles: {
        height: "0px",
      },
      parameters: {},
    };
  },

  created() {
    this.reload();
  },

  methods: {
    reload() {
      this.checkUser(this);
      this.busy = true;
      this.onSearch();
      this.$nextTick(() => {
        this.setTableHeight("holder", this.holderstyles);
      });
    },
    showUserDetails(user) {
      // this.getLoan(index,);
      bus.$emit("showLogDetails", user);
    },

    getUserLogs($state) {
      this.parameters = {
        page: this.page,
        name: this.searchQuery,
        district:
          this.filters.district === "All districts"
            ? ""
            : this.filters.district,
        start: this.formatDate(this.filters.dates.start),
        end: this.formatDate(this.filters.dates.end),
        season: this.seasonfilter.season,
      };
      axios
        .get(this.pageUrl, {
          headers: { Authorization: "Bearer " + this.user },
          params: this.parameters,
        })
        .then((response) => {
          this.page++;
          if (response.data.length >= 25) {
            this.registeredusers = this.registeredusers.concat(response.data);
            $state.loaded();
          } else if (response.data.length > 0) {
            
              this.registeredusers = this.registeredusers.concat(response.data);
            

            // this.loading = false;
            $state.loaded();
            throw false;
          } else {
            throw false;
          }
        })
        .catch((error) => {
          console.log(error);
          // this.loading = false;
          $state.complete();
        });
    },
    getOffer(user) {
      this.loadingoffer = true;
      user.amount = "loading...";
      axios
        .get(this.uri + "api/v3/loans/pezesha/user/loan-limit", {
          headers: { Authorization: "Bearer " + this.user },
          params: { user_id: user.merchant_id },
        })
        .then((response) => {
          user.amount = this.formatNum(
            response.data.data ? response.data.data.amount : 0
          );
        })
        .catch(() => {
          user.amount = 0;
        });
    },
    onSearch() {
      this.page = 1;
      this.parameters = {
        page: this.page,
        name: this.searchQuery,
        district:
          this.filters.district === "All districts"
            ? ""
            : this.filters.district,
        start: this.formatDate(this.filters.dates.start),
        end: this.formatDate(this.filters.dates.end),
        season: this.seasonfilter.season,
      };

      this.registeredusers = [];
      this.infiniteId += 1;
    },
  },
  watch: {

    filters: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
    seasonfilter: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dot {
  height: 10pt;
  width: 10pt;
  border-radius: 10pt;
  background-color: #e8e8e8;
  display: inline-block;
}
</style>
